const translateModelExport = {
  item: 'Catálogo',
  supplier: 'Fornecedores',
  'company-customer': 'Clientes',
  refurbish: 'Projetos',
  opportunity: 'Oportunidades',
  'refurbish-item-instalment': 'Pagamentos',
  task: 'Tarefas',
  shopping: 'Compras',
  refurbishItems: 'Orçamento',
  analyticalReport: 'Relatório_Analítico',
  syntheticReport: 'Relatório_Sintético',
  ABCCurveReport: 'Curva_ABC',
  payment: 'Pagamentos',
  income: 'Receitas',
  expense: 'Despesas',
  financial: 'Resultados',
  'financial/report': 'Relatório',
  'cash-flow': 'Fluxo_de_caixa',
  specificationV2: 'Orçamento',
  planning: 'Planejamento',
  transactions: 'Extrato',
  transfer: 'Transferências',
  bankAccount: 'Contas',
  financialCategory: 'Categorias',
  paymentCostCenter: 'Centro_de_custo',
  bankStatement: 'Extrato',
  budgetedAccomplished: 'Orçado X Realizado',
  orderOverview: 'Panorama_de_compras',
  quoteItems: 'Cotação',
  financialPhysical: 'Físico-Financeiro',
  measurement: 'Medição',
  SCurve: 'Curva-S',
  'financial/dre': 'Relatório_DRE',
  'financial/dailyCashFlow': 'Fluxo de caixa diário',
  dreFilter: 'Detalhes-DRE',
  cashFlowFilter: 'Detalhes Fluxo de caixa',
  budgetedAccomplishedFilter: 'Detalhes Orçado X Realizado',
  dailyCashFlowFilter: 'Detalhes Fluxo de caixa diário'
};

const cashflowOrDRE = [
  'Descrição',
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
  'Total'
];

const commomExport = ['Nome', 'Ativo?'];

const commomRefurbishItem = [
  'Nome',
  'Tipo',
  'Fornecedor',
  'Grupo',
  'Categoria',
  'Preço',
  'Quantidade',
  'Unidade',
  'Total',
  'Status',
  'Observação'
];

const AdministrativeFinancial = [
  [
    'Tipo',
    'Nome',
    'ID',
    'Status',
    'Categoria financeira',
    'Cliente',
    'Fornecedor',
    'Data de competência',
    'Projeto',
    'Conta bancária',
    'Centro de custo',
    'Valor total',
    'Parcela',
    'Valor parcela',
    'Vencimento parcela',
    'Status parcela',
    'Data de pagamento parcela',
    'Forma de pagamento parcela',
    'ID Parcela',
    'Notas fiscais'
  ]
];

const opportunityOrRefurbish = [
  [
    'Código',
    'Nome',
    'cliente (nome)',
    'Status',
    'Criado em',
    'Valor estimado',
    'Valor de ganho',
    'Metragem',
    'Expectativa de início',
    'Descrição',
    'CEP',
    'Endereço',
    'Numero',
    'Complemento',
    'Cidade',
    'Estado',
    'Colaboradores',
    'Origem'
  ]
];

const replaceReaders = {
  supplier: [
    [
      'Código',
      'Nome fantasia',
      'Razão social',
      'Tipo',
      'Tipo Pessoa',
      'Categoria',
      'Email',
      'Site',
      'Telefone Contato',
      'Responsável',
      'Nome Contato',
      'Criado em',
      'Endereço',
      'Número',
      'Complemento',
      'Bairro',
      'Cidade',
      'Estado',
      'CEP',
      'Agencia',
      'Banco',
      'Conta Corrente',
      'CPF/CNPJ',
      'RG/IE',
      'Observações',
      'Meios de pagamento'
    ]
  ],

  planning: [['Nº', 'Tipo', 'Item', 'Qtd', 'Dias', 'Data Início', 'Data Fim', 'Responsável']],
  'company-customer': [
    [
      'Código',
      'Nome',
      'Razão Social',
      'Email',
      'CPF/CNPJ',
      'RG/IE',
      'Data de nascimento',
      'Telefone',
      'Status',
      'Criado em',
      'Observações',
      'CEP',
      'Rua',
      'Número',
      'Complemento',
      'Bairro',
      'Cidade',
      'Estado',
      'Tipo'
    ]
  ],
  task: [
    [
      'Nome da Tarefa',
      'Etapa',
      'Projeto da tarefa',
      'Data de Início',
      'Data de Fim',
      'Descrição',
      'Horas estimadas',
      'Responsável',
      'Horas realizadas',
      'Prioridade',
      'Status'
    ]
  ],
  item: [['Id', 'Código', 'Tipo', 'Nome', 'Fornecedor', 'Grupo', 'Categoria', 'Preço', 'Link', 'Marca', 'Descrição']],
  installment: [
    [
      'Tipo',
      'Nome',
      'ID',
      'Status',
      'Categoria financeira',
      'Cliente',
      'Data de competência',
      'Projeto',
      'Valor total',
      'Parcela',
      'Valor parcela',
      'Vencimento parcela',
      'Status parcela',
      'Data de pagamento parcela',
      'Forma de pagamento parcela',
      'ID Parcela'
    ]
  ],
  resultbyrefurbish: [['ID', 'Nome', 'Cliente', 'Receita', 'Despesa', 'Resultado']],
  'financial/cashFlow': [cashflowOrDRE],
  refurbish: opportunityOrRefurbish,
  opportunity: opportunityOrRefurbish,
  refurbishItems: [
    [
      'Agrupamento',
      ...commomRefurbishItem,
      'Data de compra',
      'Status de compra',
      'Altura (cm)',
      'Largura (cm)',
      'Comprimento (cm)',
      'Peso (kg)'
    ]
  ],
  'refurbish-item-instalment': [[...commomRefurbishItem, 'Data Pagamento', 'Valor do pagamento']],
  shopping: [
    [
      'Agrupamento',
      ...commomRefurbishItem,
      'Altura (cm)',
      'Largura (cm)',
      'Comprimento (cm)',
      'Peso (kg)',
      'Data compra',
      'Status de compra'
    ]
  ],
  'financial/report': [['ID', 'Nome', 'Cliente', 'Receita', 'Despesa', 'Resultado']],
  financial: AdministrativeFinancial,
  expense: AdministrativeFinancial,
  income: AdministrativeFinancial,
  transfer: [['Identificador', 'Descrição', 'Valor', 'De', 'Para', 'Data']],
  payment: AdministrativeFinancial,
  'cash-flow': [cashflowOrDRE],
  transactions: [['Data', 'Transação', 'Descrição', 'Valor', 'Saldo']],
  bankAccount: [
    [
      'Descrição',
      'Nome completo do titular',
      'CPF/CNPJ',
      'Agência',
      'Conta',
      'Tipo de conta',
      'Tipo de pessoa',
      'Saldo inicial',
      'Data do saldo inicial',
      'Nome do banco'
    ]
  ],
  financialCategory: [['Ordem', 'Nome', 'Criado em', 'Tipo']],
  paymentCostCenter: [commomExport],
  bankStatement: [['Evento', 'Descrição', 'Valor', 'Conta', 'Cliente/Fornecedor']],
  budgetedAccomplished: [['Nível', 'Orçado', 'Realizado', 'Saldo', 'Desempenho']],
  orderOverview: [
    [
      'Código',
      'Item',
      'Unidade',
      'Qtd. orçada',
      'Qtd. comprada',
      'Qtd. em solicitação',
      'Qtd. em compra',
      'Balanço',
      'Custo médio orc.',
      'Custo médio real',
      '% Custo médio',
      'Custo total orc.',
      'Custo total real',
      '% Custo total'
    ]
  ],
  quoteItems: [['Nome', 'unidade', 'Qtd. Solicitada', 'Quantidade', 'preço', 'total']],
  'financial/dre': [cashflowOrDRE],
  'financial/dailyCashFlow': [['Data', 'Receitas', 'Despesas', 'Transferências', 'Saldo']],
  dreFilter: [['Descrição', 'Competência', 'Valor', 'Cliente', 'Projeto', 'Status']],
  cashFlowFilter: [['Descrição', 'Valor', 'Vencimento', 'Pagamento', 'Cliente/Fornecedor', 'Projeto', 'Status']],
  dailyCashFlowFilter: [['Descrição', 'Valor', 'Vencimento', 'Pagamento', 'Cliente/Fornecedor', 'Projeto', 'Status']],
  budgetedAccomplishedFilter: [
    ['Descrição', 'Valor', 'Vencimento', 'Pagamento', 'Cliente/Fornecedor', 'Projeto', 'Status']
  ]
};

export { translateModelExport, replaceReaders };
