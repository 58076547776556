import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import {
  faCartShopping,
  faCouch,
  faEllipsis,
  faFileSignature,
  faHelmetSafety,
  faKeySkeleton,
  faRulerCombined,
  faTrowelBricks,
  faBlockBrick,
  faInfoCircle as faSolidInfoCircle
} from '@fortawesome/pro-solid-svg-icons';

import {
  AboutContent,
  AboutYouCardGroup,
  Container,
  ContentBox,
  DescriptionContainer,
  IconCard,
  TextSchema,
  TextSelectContainer
} from './Onboarding.styled';
import Input from '../../../components/Input/Input';

import { colors, spaces, fonts } from '../../../styles/style';
import useViewport from '../../../_Hooks/useViewport';
import { Subtitle, Title } from '../../../components/Text/Text';

const { Text } = Typography;

const CompanyDetails = ({
  data = {},
  isValid,
  onChange = f => f,
  onReturn,
  onSubmit,
  isSchema = false,
  formState = {},
  customError,
  ...props
}) => {
  const { serviceList, companyTypesCategories } = useSelector(state => state.setup.systemData);
  const { schemaBusinessCustomError } = useSelector(state => state.setup.enums);
  const type = Object.entries(companyTypesCategories).find(([, types]) => types.includes(data?.idType))?.[0];

  const { isMobile } = useViewport(window.innerWidth);

  const [_data, setData] = useState(data);
  const [services, setServices] = useState({});
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  const _isMobile = isMobile();

  const iconsMap = {
    ruler: faRulerCombined,
    couch: faCouch,
    'cart-shopping': faCartShopping,
    'file-signature': faFileSignature,
    'helmet-safety': faHelmetSafety,
    'trowel-bricks': faTrowelBricks,
    'key-skeleton': faKeySkeleton,
    ellipsis: faEllipsis,
    'block-brick': faBlockBrick
  };

  const handleChange = e => {
    setData(prev => {
      const s = { onboarding: { ...prev?.onboarding, ...e } };
      onChange(s);
      return s;
    });
  };

  useEffect(() => {
    setIsDescriptionVisible(_data?.onboarding?.services?.includes(7));
  }, [_data?.onboarding?.services]);

  const CheckboxComponent = () => (
    <Container $isSchema={isSchema} {...props}>
      <Checkbox.Group
        value={_data?.onboarding?.services}
        onChange={e => handleChange({ services: e })}
        id="services-group"
      >
        <ContentBox $isSchema={isSchema} {...props}>
          {serviceList.map(s => (
            <Checkbox value={s.value} key={`service${s.value}`}>
              {s.label}
              {s.tooltipText && (
                <Tooltip title={s.tooltipText}>
                  <span id={s.tooltipId}>
                    <FontAwesomeIcon
                      style={{ marginLeft: spaces.space0 }}
                      color={colors.primary600}
                      icon={faInfoCircle}
                    />
                  </span>
                </Tooltip>
              )}
            </Checkbox>
          ))}
          {customError && formState?.touched?.onboarding && formState?.errors?.[schemaBusinessCustomError?.service] && (
            <small style={{ color: colors.red500 }}>{formState?.errors?.[schemaBusinessCustomError?.service][0]}</small>
          )}
        </ContentBox>
      </Checkbox.Group>
      {isDescriptionVisible && (
        <DescriptionContainer $isSchema={isSchema}>
          {isSchema && <TextSchema>Descreva os outros serviços</TextSchema>}
          <Input
            type={isSchema ? 'textarea' : 'text'}
            placeholder="Descreva"
            value={_data?.onboarding?.servicesDescription}
            onChange={e => handleChange({ servicesDescription: e.target.value })}
            minRows={4.5}
            id="services-description"
          />
          {customError && formState?.errors?.[schemaBusinessCustomError?.description] && (
            <small style={{ color: colors.red500 }}>
              {formState?.errors?.[schemaBusinessCustomError?.description][0]}
            </small>
          )}
        </DescriptionContainer>
      )}
    </Container>
  );

  return isSchema ? (
    <>
      <TextSchema>Quais serviços você oferece?*</TextSchema>
      {CheckboxComponent()}
    </>
  ) : (
    <AboutContent>
      <Title id="onboarding-page-title">Estamos quase lá!</Title>
      <Subtitle weight={fonts.weight600}>
        {type === 'architecture' ? 'Quantos projetos' : 'Quantas obras'} sua empresa está gerenciando no momento?
      </Subtitle>
      <TextSelectContainer>
        <Subtitle>Quantidade</Subtitle>
        <Input
          type="text"
          placeholder="Ex: 18"
          value={_data?.onboarding?.projectCount}
          onChange={e => handleChange({ projectCount: e?.target?.value })}
          style={{ width: '200px' }}
        />
      </TextSelectContainer>
      <div>
        <Subtitle weight={fonts.weight600}>Quais serviços você oferece?</Subtitle>
        <Subtitle> (múltipla escolha)</Subtitle>
      </div>
      <AboutYouCardGroup>
        {serviceList.map(service => (
          <IconCard
            key={service.value}
            onClick={() => {
              const newServices = { ...services, [service.value]: !services[service.value] };
              setServices(newServices);
              handleChange({
                services: Object.entries(newServices).reduce((acc, [value, checked]) => {
                  if (checked) {
                    acc.push(Number(value));
                  }
                  return acc;
                }, [])
              });
            }}
            $isSelected={services?.[service.value]}
          >
            <FontAwesomeIcon icon={iconsMap[service.icon]} size={_isMobile ? 'md' : 'xl'} />
            <div>
              <Text className="info-text">{service.label}</Text>
              {service.tooltipText && (
                <Tooltip title={service.tooltipText}>
                  <span id={service.tooltipId}>
                    <FontAwesomeIcon style={{ marginLeft: spaces.space0 }} icon={faSolidInfoCircle} />
                  </span>
                </Tooltip>
              )}
            </div>
          </IconCard>
        ))}
      </AboutYouCardGroup>
    </AboutContent>
  );
};

CompanyDetails.propTypes = {
  data: PropTypes.instanceOf(Object),
  isValid: PropTypes.bool,
  onReturn: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  isSchema: PropTypes.bool,
  formState: PropTypes.instanceOf(Object),
  customError: PropTypes.bool
};

export default CompanyDetails;
