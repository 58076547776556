import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Input as AntInput } from 'antd';
import { breakpoints, colors, fonts, spaces } from '../../styles/style';

const StyledInput = styled(AntInput)`
  margin: ${props => props.margin && props.margin};

  &.form-component,
  &.ant-input-affix-wrapper.formInput {
    padding: ${spaces.space0} ${spaces.space1};
    border: 1px solid transparent;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
    background-color: transparent;
    min-height: ${spaces.space4};

    @media (max-width: ${breakpoints.tablet}) {
      &.form-component,
      &.ant-input-affix-wrapper.formInput {
        padding: 0;
        border: none;
        width: 100%;
        font-size: ${fonts.sizeMd};
      }

      &[readonly],
      &.ant-input-affix-wrapper-readonly {
        font-weight: ${fonts.weight600};
      }

      .ant-input {
        font-size: ${fonts.sizeMd};
        font-weight: ${fonts.weight600};
      }
    }

    &:not([readonly]):not(.ant-input-affix-wrapper-readonly):hover {
      background-color: ${colors.neutral75};
      border-color: ${colors.neutral75};
    }

    &:not([readonly]):not(.ant-input-affix-wrapper-readonly):focus,
    &:not([readonly]):not(.ant-input-affix-wrapper-readonly).ant-input-affix-wrapper-focused {
      border-color: ${colors.neutral100};
      background-color: ${colors.white};
    }

    &[readonly],
    &.ant-input-affix-wrapper-readonly {
      background-color: transparent;
    }

    .ant-input {
      background-color: transparent;
      text-overflow: ellipsis;
      overflow: hidden;
      color: ${colors.neutral600};
    }
  }

  ${props =>
    props.withoutArrows &&
    css`
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    `}

  ${props =>
    props.neutral &&
    css`
      border-color: ${colors.neutral100};
    `}

  &:not([readonly]):not(.ant-input-affix-wrapper-readonly):focus,
  &:not([readonly]):not(.ant-input-affix-wrapper-readonly).ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: ${colors.neutral600};
  }

  &:not([readonly]):not(.ant-input-affix-wrapper-readonly):hover {
    border-color: ${colors.neutral600};
  }
`;

const StyledTextArea = styled(AntInput.TextArea)`
  margin: ${props => props.margin && props.margin};

  &.form-component {
    padding: ${spaces.space2};
    border: 1px solid ${colors.neutral100};
    transition: background-color 0.3s ease, border-color 0.3s ease;
    font-size: ${fonts.sizeSm};
    color: ${colors.neutral600};
    font-weight: ${fonts.weight400};
    background-color: transparent;
    min-height: ${spaces.space4};

    @media (max-width: ${breakpoints.tablet}) {
      &.form-component {
        padding: 0 0 ${spaces.space3} 0;
        width: 100%;
        border-radius: 0;
        border-bottom: 1px solid ${colors.neutral100};
        border-left: none;
        border-right: none;
        border-top: none;
      }
    }

    &:not([readonly]):hover {
      border-color: ${colors.neutral200};
    }

    &:not([readonly]):focus {
      border-color: ${colors.neutral200};
    }
  }
`;

const Input = ({ type, minRows, marginTop, ...props }) => {
  const inputTypes = {
    password: <StyledInput.Password {...props} />,
    textarea: <StyledTextArea autoSize={{ minRows: minRows || 3 }} {...props} style={{ marginTop: marginTop || 0 }} />
  };
  return inputTypes[type] || <StyledInput type={type} {...props} />;
};

Input.propTypes = {
  type: PropTypes.string,
  minRows: PropTypes.number,
  marginTop: PropTypes.number
};

export default Input;
