import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCirclePlus, faList, faStore } from '@fortawesome/pro-solid-svg-icons';
import { faGear, faShareAlt } from '@fortawesome/pro-regular-svg-icons';

import qs from 'qs';
import { columns as quotationMapColumns, detailsRows } from '../../lib/mapping/TableOrList/quotationMapColumns';
import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import { Paragraph, Subtitle } from '../Text/Text';
import usePagination from '../../_Hooks/usePagination';
import QuotationMapBulkActions from '../BulkActions/QuotationMapBulkActions';
import EditQuoteSupplierDrawer from '../Drawer/EditQuoteSupplierDrawer';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { Div, colors, spaces } from '../../styles/style';
import AddQuoteSuppliersDrawer from '../Drawer/AddQuoteSuppliersDrawer';
import SearchInput from '../Filters/SearchInput';
import QuotationMapConfigModal from '../Modal/QuotationMapConfigModal';
import ActionButtonDropdown from '../Dropdown/ActionButtonDropdown';
import { exportToModel } from '../../lib/helpers/helper';
import VirtualizedTable from './VirtualizedTable';
import Button from '../Button/Button';
import ShareModuleModal from '../Modal/ShareModuleModal';

const limit = 20;

const columnsWidthMap = {
  item: 250,
  code: 90,
  quantity: 90,
  units: 80,
  purchaseDate: 100,
  price: 100,
  total: 100
};

const supplierColumnsWidthMap = {
  checkbox: 60,
  quantity: 108,
  price: 100,
  total: 100,
  sum: 368
};

const itemsHeaderComponentWidth = 140;

const TableHeaderAndIconRender = ({ icon, text, onClickRenderSupplier, isCustomer }) => (
  <Div width={`${itemsHeaderComponentWidth}px`} gap={spaces.space1}>
    <FontAwesomeIcon icon={icon} color={colors.neutral400} />
    <Paragraph type="small">
      <strong>{text}</strong>
    </Paragraph>
    {onClickRenderSupplier && !isCustomer ? (
      <Button
        size="small"
        padding={0}
        text
        type="primary"
        onClick={() => onClickRenderSupplier(true)}
        style={{ height: '100%' }}
      >
        <FontAwesomeIcon icon={faCirclePlus} size="xl" id="addSupplierButton" style={{ margin: 0 }} />
      </Button>
    ) : null}
  </Div>
);

TableHeaderAndIconRender.propTypes = {
  icon: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  onClickRenderSupplier: PropTypes.func,
  isCustomer: PropTypes.bool
};

const QuotationsMap = ({ idQuote, quote, reload, readOnly, isCustomer }) => {
  const { quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const location = useLocation();
  const { projeto: idRefurbish } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [quoteSuppliers, setQuoteSuppliers] = useState([]);
  const [quoteItems, setQuoteItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);
  const [totals, setTotals] = useState({});
  const [currentSelectedSupplierCheckbox, setCurrentSelectedSupplierCheckbox] = useState(null);
  const [selectedQuoteSupplierStatus, setSelectedQuoteSupplierStatus] = useState(null);
  const [editQuoteSupplierDrawerData, setEditQuoteSupplierDrawerData] = useState(null);
  const [openSuppliersDrawer, setOpenSuppliersDrawer] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [showConfigModal, setShowConfigModal] = useState(false);
  const [divWidth, setWidth] = useState(0);
  const [firstLoading, setFirstLoading] = useState(true);

  const divRef = useRef(null);

  const isMobile = useViewport(window.innerWidth).isMobile();

  const history = useHistory();

  const columnsToShow = (isCustomer ? quote?.clientColumns : quote?.myColumns) || {};
  const supplierColumnsToShow = (isCustomer ? quote?.clientSuppliersColumns : quote?.mySuppliersColumns) || {};

  const { activeColumns, columnsSize } = useMemo(() => {
    let _activeColumns = 0;
    let _columnsSize = columnsWidthMap.item;

    Object.entries(columnsToShow).forEach(([key, value]) => {
      if (value) {
        _activeColumns++;
        _columnsSize += columnsWidthMap[key];
      }
    });

    return { activeColumns: _activeColumns, columnsSize: _columnsSize };
  }, [quote]);

  const activeQuoteSuppliersColumns = useMemo(() => {
    return quoteSuppliers?.filter(quoteSupplier => supplierColumnsToShow?.[quoteSupplier?.id] !== false);
  }, [quote, quoteSuppliers]);

  const { totalDistanceForSupplierHeader, fixedColumns } = useMemo(() => {
    if (!divWidth) return { totalDistanceForSupplierHeader: 0, tableWidth: 0, fixedColumns: true };

    const maxDistance = divWidth - 2 * itemsHeaderComponentWidth;

    const tableTotalWidth = isCustomer
      ? columnsSize +
        activeQuoteSuppliersColumns?.length * (supplierColumnsWidthMap.sum - supplierColumnsWidthMap.checkbox)
      : columnsSize + activeQuoteSuppliersColumns?.length * supplierColumnsWidthMap.sum;

    const result =
      (divWidth >= tableTotalWidth ? (divWidth / tableTotalWidth) * columnsSize : columnsSize) -
      itemsHeaderComponentWidth;

    const _fixedColumns = maxDistance >= result;

    if (!activeQuoteSuppliersColumns?.length)
      return {
        totalDistanceForSupplierHeader: divWidth / 2 - itemsHeaderComponentWidth,
        fixedColumns: _fixedColumns
      };

    return {
      totalDistanceForSupplierHeader: !_fixedColumns ? maxDistance : result,
      fixedColumns: _fixedColumns
    };
  }, [divWidth, activeQuoteSuppliersColumns, columnsSize]);

  const { loading: suppliersLoading, handleGet, handleUpdate } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const { totalItems, handleGet: getQuoteItems } = useCRUD({
    model: 'quoteItems',
    immediatelyLoadData: false
  });

  const { handleGet: getSingleQuoteItem } = useCRUD({
    model: 'quoteItems',
    immediatelyLoadData: false
  });

  const { handleUpdate: updateQuoteItemSuppliers, handleCreate: createQuoteItemSuppliers } = useCRUD({
    model: 'quoteItemSuppliers',
    immediatelyLoadData: false
  });

  const { handleGet: getTotals, handleUpdate: updateQuote } = useCRUD({
    model: 'quote',
    immediatelyLoadData: false
  });

  const fillSummary = className => {
    const _fillSummary = [];
    let index = 0;

    Object.values(columnsToShow).forEach(value => {
      if (value) {
        index++;
        _fillSummary.push(<Table.Summary.Cell key={`${value}-${index}`} index={index} className={className} />);
      }
    });

    if (_fillSummary.length) {
      _fillSummary.pop();
      _fillSummary.push(
        <Table.Summary.Cell
          key={`summary-${index}`}
          className={`cell-with-before cell-before-full ${className}`}
          index={index}
        />
      );
    }

    return _fillSummary;
  };

  const loadSuppliers = () => {
    handleGet({
      refetchOptions: {
        where: {
          idQuote
        },
        include: ['supplier'],
        order: [['status', 'DESC']]
      }
    }).then(response => {
      if (response?.error) return;
      setFirstLoading(false);
      setQuoteSuppliers(response);
    });
  };

  const quoteItemsRefetchOptions = {
    where: {
      idQuote,
      ...(filter?.name && { ...filter?.name?.where })
    },
    include: [
      {
        model: 'quoteItemSuppliers',
        separate: true,
        as: 'quoteItemSuppliers',
        include: [{ model: 'quoteSuppliers', as: 'quoteSupplier', attributes: ['status'] }]
      },
      'units'
    ]
  };

  const loadItems = ({ delay = false, offset } = {}) => {
    getQuoteItems({
      refetchOptions: {
        ...quoteItemsRefetchOptions,
        order: [['id', 'desc']],
        limit,
        offset: offset || 1,
        generateLoading: delay
      }
    }).then(response => {
      if (response?.error) return;
      setQuoteItems(prev => (offset > 1 ? [...prev, ...response] : response));
    });
  };

  const loadTotals = () => {
    getTotals({ refetchPathOptions: `/${idQuote}/totals` }).then(response => {
      if (response?.error) return;
      setTotals(response);
    });
  };

  const handleLoadOrSetOffset = ({ delay, offset = 1 } = {}) => {
    setSelectedItems({});
    loadTotals();
    loadItems({ delay, offset });
  };

  const handleRefreshAll = () => {
    handleLoadOrSetOffset();
    loadSuppliers();
  };

  const { handleScroll, setTotalItems, setOffset } = usePagination({
    totalItems,
    handleLoad: handleLoadOrSetOffset,
    listLength: quoteItems?.length,
    initialOffset: 1,
    filter
  });

  useEffect(() => {
    if (divRef.current) {
      const { width } = divRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [suppliersLoading]);

  useEffect(() => {
    setTotalItems(totalItems);
  }, [totalItems]);

  useEffect(() => {
    loadSuppliers();
  }, []);

  useEffect(() => {
    if (!Object.keys(selectedItems).length) setCurrentSelectedSupplierCheckbox(null);
  }, [selectedItems]);

  useEffect(() => {
    if (!currentSelectedSupplierCheckbox) return;

    const _quoteSupplierStatus = quoteSuppliers.find(q => q.id === currentSelectedSupplierCheckbox)?.status;
    setSelectedQuoteSupplierStatus(_quoteSupplierStatus);
  }, [currentSelectedSupplierCheckbox]);

  const toggleSummary = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  const editRow = ({ values: _values, idQuoteSupplier, idQuoteItem, idQuoteItemSupplier }) => {
    const values = { ..._values, idQuoteSuppliers: idQuoteSupplier, idQuoteItem };
    const func = idQuoteItemSupplier ? updateQuoteItemSuppliers : createQuoteItemSuppliers;

    func({
      values,
      id: idQuoteItemSupplier,
      refresh: false
    }).then(response => {
      if (response?.error) return;

      getSingleQuoteItem({
        refetchPathOptions: `/${response?.idQuoteItem}`,
        refetchOptions: quoteItemsRefetchOptions
      }).then(quoteItem => {
        setQuoteItems(prev =>
          prev.map(item => {
            if (item.id !== quoteItem?.id) return item;

            return {
              ...item,
              ...quoteItem
            };
          })
        );

        loadTotals();
      });
    });
  };

  const editDetails = ({ id, values, refresh = ['items', 'suppliers', 'quote', 'totals', 'selected'] }) => {
    handleUpdate({ id, values, refresh: false, noLoading: true }).then(response => {
      if (response?.error) return;

      setQuoteSuppliers(prev =>
        prev.map(quoteSupplier =>
          quoteSupplier.id === response.id ? { ...response, supplier: quoteSupplier.supplier } : quoteSupplier
        )
      );

      if (refresh?.includes('selected')) setSelectedItems({});
      if (refresh?.includes('items')) loadItems();
      if (refresh?.includes('suppliers')) loadSuppliers();
      if (refresh?.includes('quote')) reload();
      if (refresh?.includes('totals')) loadTotals();
    });
  };

  const onCheck = (id, idQuoteSupplier, checked) => {
    if (!currentSelectedSupplierCheckbox) setCurrentSelectedSupplierCheckbox(idQuoteSupplier);

    setSelectedItems(prev => {
      const newSelected = { ...prev };
      if (checked) newSelected[`${id}-${idQuoteSupplier}`] = id;
      else delete newSelected[`${id}-${idQuoteSupplier}`];
      return newSelected;
    });
  };

  const redirectToCreateOrder = idSupplier => {
    const _idSupplier = idSupplier || quoteSuppliers.find(q => q.id === currentSelectedSupplierCheckbox)?.idSupplier;

    history.push(
      `/profissional/gestao-de-compras/ordem-de-compras/novo?cotacao=${idQuote}&fornecedor=${_idSupplier}${
        idRefurbish ? `&projeto=${idRefurbish}` : ''
      }`
    );
  };

  const handleExportSelectedItems = exportType => {
    return exportToModel({
      handleGet,
      pathPrefix: `${idQuote}`,
      exportType,
      prefixName: 'quoteSuppliers'
    });
  };

  const detailRowsTypes = detailsRows({
    editDetails,
    totals,
    onClickTotal: redirectToCreateOrder,
    quoteSupplierStatus,
    readOnly
  });

  const getSummaryCells = ({ key, type, strong, className }) =>
    activeQuoteSuppliersColumns.map((quoteSupplier, index) => {
      const val = detailRowsTypes[type]({ quoteSupplier, key, isCustomer });
      const isApproved = quoteSupplier?.approved;
      const isBuy = quoteSupplier?.status === quoteSupplierStatus.buy.id;
      const isAnswered = quoteSupplierStatus.answeredOptions.includes(quoteSupplier?.status);
      const isTotal = key === 'totals';
      const isSubTotal = key === 'subTotals';

      let _className = className;

      if (isBuy && isTotal) _className = 'cell-color-primary-95';
      else if (isApproved && isTotal) _className = 'cell-color-primary-40';
      else if ((isApproved || isBuy) && isSubTotal) _className = 'cell-color-primary-95';
      else if ((isApproved || isBuy) && !isTotal && !isSubTotal) _className = 'cell-color-primary-99';
      else if (!isApproved && isTotal && isAnswered) _className = 'cell-color-neutral-95';
      return (
        <Table.Summary.Cell
          key={`${key}-${quoteSupplier.id}`}
          index={activeColumns + index + 1}
          className={`cell-with-before cell-before-full ${_className}`}
          colSpan={isCustomer ? 3 : 4}
          align="right"
        >
          <Div justify="flex-end" margin={`0 ${spaces.space1}`}>
            {strong ? (
              <Paragraph type="small">
                <strong>{val}</strong>
              </Paragraph>
            ) : (
              val
            )}
          </Div>
        </Table.Summary.Cell>
      );
    });

  const generateSummaryRow = ({ name, key, type }) => {
    return (
      <Table.Summary.Row height={spaces.space6}>
        <Table.Summary.Cell index={0} className={!activeColumns ? 'cell-with-before cell-before-full' : undefined}>
          <Paragraph type="small">{name}</Paragraph>
        </Table.Summary.Cell>
        {fillSummary()}
        {getSummaryCells({ key, type, className: 'cell-color-white' })}
      </Table.Summary.Row>
    );
  };

  const ExpandableSummary = (
    <Table.Summary fixed>
      <Table.Summary.Row height={spaces.space6}>
        <Table.Summary.Cell
          index={0}
          className={`cell-color-primary-99 ${!activeColumns ? 'cell-with-before cell-before-full' : undefined}`}
        >
          <Div gap={spaces.space1}>
            <Div align="left" width={spaces.space2} $fullHeight margin="0">
              <FontAwesomeIcon
                onClick={() => toggleSummary()}
                icon={isSummaryOpen ? faCaretDown : faCaretRight}
                style={{ cursor: 'pointer' }}
                color={colors.neutral600}
                size="lg"
              />
            </Div>
            <Subtitle type="secondary">
              <strong>Subtotal e Detalhes</strong>
            </Subtitle>
          </Div>
        </Table.Summary.Cell>
        {fillSummary('cell-color-primary-99')}
        {getSummaryCells({ key: 'subTotals', type: 'subTotal', strong: true, className: 'cell-color-primary-99' })}
      </Table.Summary.Row>
      {isSummaryOpen && (
        <>
          {generateSummaryRow({ name: 'Frete', key: 'shipping', type: 'price' })}
          {generateSummaryRow({ name: 'Descontos', key: 'discount', type: 'price' })}
          {generateSummaryRow({ name: 'Impostos', key: 'taxes', type: 'price' })}
          {generateSummaryRow({ name: 'Previsão de entrega', key: 'expectedDate', type: 'date' })}
          {generateSummaryRow({ name: 'Prazo de validade da cotação', key: 'dueDate', type: 'date' })}
        </>
      )}
      <Table.Summary.Row height={spaces.space7}>
        <Table.Summary.Cell
          index={0}
          className={`cell-color-primary-99 ${!activeColumns ? 'cell-with-before cell-before-full' : undefined}`}
        >
          <Div margin={`0 0 0 ${spaces.space3}`} direction="column" align="flex-start">
            <Subtitle type="secondary">
              <strong>Total</strong>
            </Subtitle>
            {columnsToShow?.total && (
              <Div gap={spaces.space0}>
                <Paragraph type="small"> Orçado:</Paragraph>
                <Paragraph type="small">
                  <strong>{formatCurrency(totals?.quoteItemsTotal || 0)}</strong>
                </Paragraph>
              </Div>
            )}
          </Div>
        </Table.Summary.Cell>
        {fillSummary('cell-color-primary-99')}
        {getSummaryCells({ key: 'totals', type: 'total', className: 'cell-color-primary-99' })}
      </Table.Summary.Row>
    </Table.Summary>
  );

  const SearchName = (
    <SearchInput
      id="search-input"
      appliedFilters={filter}
      setAppliedFilters={setFilter}
      textSearchKey="name"
      placeholder="Pesquisar"
      width={isMobile ? '100%' : '232px'}
    />
  );

  const _quotationMapColumns = useMemo(
    () =>
      quotationMapColumns({
        activeQuoteSuppliersColumns,
        editRow,
        onCheck,
        currentSelectedSupplierCheckbox,
        handleLoad: handleRefreshAll,
        handleOpenEditQuoteSupplierDrawer: quoteSupplier => setEditQuoteSupplierDrawerData(quoteSupplier),
        editDetails,
        columnsToShow,
        supplierColumnsWidthMap,
        columnsWidthMap,
        selectedItems,
        totals,
        fixedColumns,
        readOnly,
        isMobile
      }),
    [
      activeQuoteSuppliersColumns,
      currentSelectedSupplierCheckbox,
      selectedItems,
      totals,
      fixedColumns,
      suppliersLoading,
      filter,
      quote,
      isSummaryOpen,
      readOnly
    ]
  );

  return (
    <>
      {firstLoading ? (
        <CenteredLoader />
      ) : (
        <Div direction="column" align="flex-start" ref={divRef} gap={spaces.space2} $fullWidth>
          <Div justify="space-between" $fullWidth>
            <Div $fullWidth>{SearchName}</Div>
            <Div gap={spaces.space1}>
              <ActionButtonDropdown
                onExportCsvClick={() => handleExportSelectedItems('csv')}
                onExportXlsxClick={() => handleExportSelectedItems('xlsx')}
                showPdf={false}
                text="Exportar"
                dropdownPadding="0"
              />
              {!isCustomer && (
                <>
                  <Button id="shareButton" type="primary" text={isMobile} ghost onClick={() => setOpenShareModal(true)}>
                    {isMobile ? <FontAwesomeIcon icon={faShareAlt} size="xl" /> : 'Compartilhar com cliente'}
                  </Button>
                  <Button id="configButton" type="primary" text onClick={() => setShowConfigModal(true)}>
                    <FontAwesomeIcon icon={faGear} size="xl" />
                  </Button>
                </>
              )}
            </Div>
          </Div>
          <Div gap={`${totalDistanceForSupplierHeader}px`}>
            <TableHeaderAndIconRender icon={faList} text="Itens" />
            <TableHeaderAndIconRender
              icon={faStore}
              text="Fornecedores"
              onClickRenderSupplier={setOpenSuppliersDrawer}
              isCustomer={isCustomer}
            />
          </Div>
          <VirtualizedTable
            id="quotation-map"
            columns={_quotationMapColumns}
            list={quoteItems}
            rowSelection={false}
            refreshColumns
            withCommonRow
            scroll={{ y: '380px', x: '100%' }}
            summary={() => ExpandableSummary}
            onScroll={handleScroll}
            resizable={false}
            $fixedWidth
          />
        </Div>
      )}
      <>
        {Object.keys(selectedItems).length > 0 && (
          <QuotationMapBulkActions
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            redirectToCreateOrder={redirectToCreateOrder}
            currentSelectedSupplierCheckbox={currentSelectedSupplierCheckbox}
            reload={() => {
              setOffset(1);
              handleLoadOrSetOffset();
            }}
            selectedQuoteSupplierStatus={selectedQuoteSupplierStatus}
          />
        )}
        {editQuoteSupplierDrawerData && (
          <EditQuoteSupplierDrawer
            open
            isCustomer={isCustomer}
            idQuoteSupplier={editQuoteSupplierDrawerData?.id}
            handleClose={refreshOnClose => {
              setEditQuoteSupplierDrawerData(null);
              if (refreshOnClose) handleRefreshAll();
            }}
          />
        )}
        {openSuppliersDrawer && (
          <AddQuoteSuppliersDrawer
            idQuote={idQuote}
            onClose={() => setOpenSuppliersDrawer(false)}
            quoteSupplierList={quoteSuppliers}
            afterAddQuoteSupplier={() => {
              loadSuppliers();
              reload();
            }}
          />
        )}
        {showConfigModal && (
          <QuotationMapConfigModal
            data={quote}
            onClose={() => setShowConfigModal(false)}
            onSubmit={values =>
              updateQuote({ id: idQuote, values, refresh: false, noLoading: true }).then(resp => {
                if (resp?.error) return;

                reload(true);
              })
            }
            quoteSuppliers={quoteSuppliers}
          />
        )}
        {openShareModal && (
          <ShareModuleModal
            model="quote"
            onClose={() => setOpenShareModal(false)}
            idRefurbish={quote?.idRefurbish}
            itemId={idQuote}
          />
        )}
      </>
    </>
  );
};

QuotationsMap.propTypes = {
  idQuote: PropTypes.string,
  quote: PropTypes.instanceOf(Object),
  reload: PropTypes.func,
  readOnly: PropTypes.bool,
  isCustomer: PropTypes.bool
};

export default QuotationsMap;
