import React from 'react';
import * as Yup from 'yup';
import { faCalendar, faClock } from '@fortawesome/pro-regular-svg-icons';
import { faCircleSmall, faFlag } from '@fortawesome/pro-solid-svg-icons';
import PerformedHoursDropdown from '../../../components/Dropdown/PerformedHoursDropdown';
import { toHHMM } from '../../helpers/helper';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

const TaskSchemaV2 = Yup.object().shape({
  plStartDate: Yup.date().nullable(),
  plEndDate: Yup.date().nullable(),
  duration: Yup.number().nullable(),
  responsible: Yup.object().nullable()
});

const TaskMappingV2 = ({
  idRefurbish,
  idTemplate,
  onChangeDateRelatedField,
  workDays,
  dayoffs,
  isTemplate,
  idTask,
  totalPerformedHours,
  refurbishStatusAllowed,
  canChangeRefurbish,
  taskAlreadyHasLinks,
  onPerformHoursDropdownClose
}) => ({ refurbish, startDate }) => {
  const disabled = !idRefurbish && !idTemplate;
  return {
    ...(canChangeRefurbish && {
      refurbish: {
        name: 'Projeto/Oportunidade',
        type: 'select',
        uniqueInLine: !refurbish,
        valueField: 'id',
        displayField: 'label',
        model: 'refurbish',
        placeholder: 'Selecione',
        cantEditTooltip: taskAlreadyHasLinks
          ? 'Não é possível alterar o projeto de uma tarefa que possui vínculos de dependências'
          : undefined,
        readOnly: taskAlreadyHasLinks,
        clearable: true,
        isChangeableOptions: true,
        modelOptions: {
          where: { idStatus: refurbishStatusAllowed, or: [{ '$activeStep.isActive$': true }, { idStep: null }] },
          include: ['activeStep'],
          order: [['name'], ['id']]
        },
        hasChildren: true,
        aliasOptions: ({ label }) => label,
        changeOptions: ['refurbishStep']
      }
    }),
    ...(isTemplate
      ? {
          templateStep: {
            name: 'Etapa do template',
            type: 'select',
            uniqueInLine: !canChangeRefurbish,
            valueField: 'value',
            displayField: 'title',
            placeholder: 'Selecione',
            isChangeableOptions: true,
            extraPropsOnOptions: ['color'],
            clearable: true,
            icon: faCircleSmall,
            model: 'templateStep',
            modelOptions: {
              where: {
                or: [{ idTemplate }, { id: 1 }]
              },
              order: [['order']]
            },
            aliasOptions: ({ label }) => label
          }
        }
      : {
          ...((refurbish || idRefurbish) && {
            refurbishStep: {
              name: 'Etapa do projeto',
              type: 'select',
              uniqueInLine: !canChangeRefurbish,
              placeholder: 'Selecione',
              valueField: 'value',
              displayField: 'title',
              isChangeableOptions: true,
              clearable: true,
              icon: faCircleSmall,
              extraPropsOnOptions: ['color'],
              parent: canChangeRefurbish && {
                name: 'refurbish',
                lazyLoad: disabled,
                makeRefetchOptions: ({ field, value }) => ({
                  where: {
                    or: [{ [field]: value }, { id: 1 }]
                  },
                  order: [['order']]
                })
              },
              model: 'refurbishStep',
              modelOptions: {
                where: {
                  or: [{ idRefurbish: refurbish?.value || idRefurbish }, { id: 1 }]
                },
                order: [['order']]
              },
              aliasOptions: ({ label }) => {
                return label;
              }
            }
          })
        }),
    status: {
      name: 'Status',
      type: 'statusDropdown',
      dataType: 'taskStatus',
      valueField: 'value',
      tooltip: false,
      clearable: true,
      CustomLabel: item => {
        return <StatusSelector inlineText status={item} />;
      }
    },
    priority: {
      name: 'Prioridade',
      type: 'statusDropdown',
      dataType: 'taskPriority',
      valueField: 'value',
      clearable: true,
      icon: faFlag
    },
    ...(!isTemplate && {
      startDate: {
        name: 'Data de início',
        type: ['date', 'timeHours'],
        dateProps: {
          property: 'startDate',
          placeholder: 'Selecione',
          icon: faCalendar,
          tooltip: false,
          clearable: true,
          externalOnChange: !isTemplate ? onChangeDateRelatedField('startDate') : undefined,
          externalOnBlur: !isTemplate ? onChangeDateRelatedField('startDate') : undefined,
          workDays,
          dayoffs,
          dateFormat: 'DD/MM/YY'
        },
        timeHoursProps: {
          placeholder: 'Selecione',
          property: 'startHours',
          icon: faClock,
          tooltip: false,
          clearable: true
        }
      },
      endDate: {
        name: 'Data de fim',
        type: ['date', 'timeHours'],
        dateProps: {
          property: 'endDate',
          placeholder: 'Selecione',
          icon: faCalendar,
          tooltip: false,
          clearable: true,
          externalOnChange: !isTemplate ? onChangeDateRelatedField('endDate') : undefined,
          externalOnBlur: !isTemplate ? onChangeDateRelatedField('endDate') : undefined,
          workDays,
          dayoffs,
          minDate: startDate,
          dateFormat: 'DD/MM/YY'
        },
        timeHoursProps: {
          placeholder: 'Selecione',
          property: 'endHours',
          icon: faClock,
          tooltip: false,
          clearable: true
        }
      }
    }),
    duration: {
      name: 'Duração',
      type: 'number',
      md: 12,
      tooltip: false,
      unit: 'dias',
      placeholder: '0',
      decimalCount: 0,
      clearable: true,
      externalOnBlur: !isTemplate ? onChangeDateRelatedField('duration') : undefined
    },
    responsible: {
      name: 'Responsável',
      type: 'avatar',
      md: 12,
      clearable: true,
      tooltip: false
    },
    estimativeDuration: {
      name: 'Horas estimadas',
      type: 'timeHours',
      icon: faClock,
      md: 12,
      tooltip: false,
      placeholder: 'Selecione',
      clearable: true,
      startHideOnMobile: true
    },
    ...(idTask && {
      totalPerformedHours: {
        name: 'Horas realizadas',
        type: 'custom',
        icon: faClock,
        md: 12,
        tooltip: false,
        placeholder: 'Selecione',
        decimalCount: 0,
        clearable: false,
        formId: 'planningForm',
        startHideOnMobile: true,
        formatter: value => toHHMM(value),
        // eslint-disable-next-line react/prop-types
        Component: ({ externalSetOpen, open }) => (
          <PerformedHoursDropdown
            totalHours={totalPerformedHours}
            idTask={idTask}
            externalSetOpen={externalSetOpen}
            forceOpen={open}
            onClose={_totalPerformedHours => {
              onPerformHoursDropdownClose(_totalPerformedHours);
            }}
            isForm
            property="totalPerformedHours"
          />
        )
      }
    })
  };
};

export { TaskSchemaV2, TaskMappingV2 };
