import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import useCRUD from '../../_Hooks/useCRUD';

import Box from '../../components/Box/Box';
import Image from '../../components/Images/steps.svg';
import { colors, fonts } from '../../styles/style';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import StepList from '../../components/List/StepList';

const { Text } = Typography;

const StyledBox = styled(Box)`
  ${props =>
    props.$noPadding &&
    css`
      padding: 0;
    `}
`;

const Steps = ({ hideHeader, skipConfirmation, isOnboarding, $listItemStyles }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);

  const isBO = user.userType === userType.operator.value;

  const [changedStep, setChangedStep] = useState(null);
  const options = isBO
    ? {
        where: { id: { gte: 2 }, idCompany: null },
        order: [['order'], ['createdAt']]
      }
    : {
        onlyMine: isOnboarding,
        where: {
          ...(isOnboarding
            ? {
                id: { gte: 2 },
                isKanban: true
              }
            : {
                or: [{ idCompany: user.idCompany }, { fixed: true }]
              })
        },
        include: ['refurbishes', 'templates'],
        order: [['order'], ['id']]
      };

  const { list, handleCreate, handleUpdate, handleDelete, loading, totalItems } = useCRUD({
    model: 'step',
    options
  });

  const handleConfirm = () => {
    if (!changedStep) {
      return;
    }

    const { id, name, action, order } = changedStep;

    const updateOrDelete = action === 'delete' ? handleDelete({ id }) : handleUpdate({ id, values: { name, order } });

    updateOrDelete.then();
  };

  useEffect(() => {
    if (skipConfirmation) handleConfirm();
  }, [changedStep]);

  return (
    <>
      {!hideHeader && (
        <Box elevation={null} mb={2}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space direction="vertical" size={16} style={{ marginRight: 24 }}>
              <Text style={{ fontSize: fonts.sizeLg }}>Status</Text>
              <Text style={{ color: colors.neutral400, fontSize: 12 }}>
                Ao alterar o nome de um status que esteja em uso em algum projeto, essa alteração irá refletir também no
                projeto.
                <br />
                <br />
                Ao remover um status que esteja em uso em algum projeto, esse status será removido também no projeto.
              </Text>
            </Space>
            <img src={Image} alt="Imagem" />
          </div>
        </Box>
      )}
      <StyledBox $noPadding={hideHeader}>
        <StepList
          list={list}
          onSubmit={handleCreate}
          onChange={(item, values) => handleUpdate({ id: item.id, values })}
          onDelete={item =>
            setChangedStep({
              id: item?.id,
              name: item?.name,
              refurbishes: item?.refurbishes?.length,
              action: 'delete'
            })
          }
          totalItems={totalItems}
          loading={loading}
          isOnboarding={isOnboarding}
          $listItemStyles={$listItemStyles}
        />
      </StyledBox>
      {changedStep && !skipConfirmation ? (
        <ConfirmModal
          text={`Deseja realmente ${changedStep?.action === 'delete' ? 'excluir' : 'alterar'} este status? `}
          onSubmit={handleConfirm}
          onClose={() => setChangedStep(null)}
        />
      ) : null}
    </>
  );
};

Steps.propTypes = {
  hideHeader: PropTypes.bool,
  skipConfirmation: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  $listItemStyles: PropTypes.instanceOf(Object)
};

export default Steps;
