import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromLine } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';

import Drawer from './Drawer';
import { Subtitle, Paragraph } from '../Text/Text';
import { Div, colors, spaces } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import { Context } from '../../contexts/GeneralContext';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import ObservationAndFilesComponent from '../../_Pages/Purchases/ObservationAndFilesComponent';
import DatePicker from '../Datepicker/Datepicker';
import Button from '../Button/Button';
import QuoteSupplierActionDropdown from '../Dropdown/QuoteSupplierActionDropdown';
import { ComponentToPdf } from '../../lib/helpers/exportToPdf';
import RichTextViewer from '../RichTextEditor/RichTextViewer';
import useViewport from '../../_Hooks/useViewport';
import QuoteSupplierItemsTable from '../Table/QuoteSupplierItemsTable';
import SendQuoteToSupplierModal from '../Modal/SendQuoteToSupplierModal';
import CenteredLoader from '../Loader/CenteredLoader';
import ApproveButton from '../Button/ApproveButton';
import Action from '../Button/Action';

const FieldContainer = ({ ...props }) => (
  <Div
    direction="column"
    align="flex-start"
    padding={`${spaces.space4} ${spaces.space2}`}
    gap={spaces.space2}
    $fullWidth
    {...props}
  />
);

const EditQuoteSupplierDrawer = ({ open, idQuoteSupplier, handleClose, isPrint, isCustomer }) => {
  const {
    quoteSupplierStatus: { pending, waitingResponse, refused, answered, analyzed, buy, canApprove }
  } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer) || {};

  const [values, setValues] = useState();
  const { paymentInfo, observation, expectedDate, dueDate, responseDate, status, files: valuesFiles } = values || {};

  const [files, setFiles] = useState(valuesFiles || []);

  const [isPrinting, setIsPrinting] = useState(false);
  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [openSendToSupplierModal, setOpenSendToSupplierModal] = useState(null);

  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const printComponentRef = useRef();

  const history = useHistory();

  const readOnly = isCustomer || isPrinting;

  const { data, handleGet, handleUpdate: updateQuoteSuppliers, loading } = useCRUD({
    model: 'quoteSuppliers',
    pathOptions: `/${idQuoteSupplier}`,
    options: { include: ['supplier', 'files'] },
    immediatelyLoadData: !!idQuoteSupplier
  });

  const setField = key => value => {
    setValues(prev => ({ ...prev, [key]: value }));
  };

  const handleSave = _values => {
    return updateQuoteSuppliers({
      values: {
        ...values,
        ..._values,
        files
      },
      displayToast: true,
      refresh: false
    }).then(response => {
      if (response?.error) return response;
      setRefreshOnClose(true);
      return response;
    });
  };

  const onClickPending = () =>
    handleSave().then(response => {
      if (response?.error) return;
      setOpenSendToSupplierModal(true);
    });

  const onClickWaitingResponse = () =>
    handleSave({ status: answered.id }).then(response => {
      if (response?.error) return;
      handleGet();
    });

  const onClickAnsweredOrAnalyzed = () =>
    handleSave().then(response => {
      if (response?.error) return;
      const { idQuote, idSupplier } = data || {};
      history.push(`/profissional/gestao-de-compras/ordem-de-compras/novo?cotacao=${idQuote}&fornecedor=${idSupplier}`);
    });

  const quoteSupplierStatusMap = {
    [pending.id]: {
      ...pending,
      saveTitle: 'Salvar e Enviar pedido de cotação',
      onClick: onClickPending
    },
    [waitingResponse.id]: {
      ...waitingResponse,
      saveTitle: 'Salvar e mover para respondida',
      onClick: onClickWaitingResponse
    },
    [refused.id]: { ...refused },
    [answered.id]: {
      ...answered,
      saveTitle: `Salvar e criar ordem de compra ${_isMobile ? '' : 'e contratação'}`,
      onClick: onClickAnsweredOrAnalyzed
    },
    [analyzed.id]: {
      ...analyzed,
      saveTitle: 'Salvar e criar ordem de compra e contratação',
      onClick: onClickAnsweredOrAnalyzed
    },
    [buy.id]: { ...buy },
    disabledStatusUpdate: [buy.id]
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => (isPrint ? handleClose(false) : setIsPrinting(false))
  });

  useEffect(() => {
    setFiles(valuesFiles);
  }, [valuesFiles]);

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
    }
  }, [isPrinting]);

  useEffect(() => {
    if (isPrint && printComponentRef.current) {
      setTimeout(() => setIsPrinting(true), 500);
    }
  }, [isPrint, printComponentRef.current]);

  useEffect(() => {
    setField('files')(files);
  }, [files]);

  useEffect(() => {
    setValues(data);
  }, [data]);

  const onClose = () => handleClose(refreshOnClose);

  const footer = (
    <Div justify="space-between" $fullWidth>
      {!_isMobile && (
        <Button text onClick={onClose}>
          Fechar
        </Button>
      )}

      <Div justify="flex-end" gap={spaces.space1} $fullWidth>
        <QuoteSupplierActionDropdown
          quoteSupplier={values}
          handleLoad={({ close }) => {
            setRefreshOnClose(true);

            if (close) {
              handleClose(true);
              return;
            }

            handleGet();
          }}
          handlePrint={() => setIsPrinting(true)}
          isDrawer
        >
          <Action text />
        </QuoteSupplierActionDropdown>
        {quoteSupplierStatusMap[status]?.onClick && (
          <Button type="primary" ghost onClick={quoteSupplierStatusMap[status]?.onClick} loading={loading}>
            {quoteSupplierStatusMap[status]?.saveTitle}
          </Button>
        )}
        <Button
          type="primary"
          onClick={() =>
            handleSave().then(response => {
              if (response?.error) return;
              handleGet();
            })
          }
          loading={loading}
        >
          Salvar
        </Button>
      </Div>
    </Div>
  );

  const DateComponent = ({ id, description, value, setterFunc }) => (
    <Div direction="column" align="flex-start" gap={spaces.space1} width="272px">
      <Paragraph type="small">{description}</Paragraph>
      {readOnly ? (
        <Paragraph type="small">{value ? dayjs(value).format('DD/MM/YYYY') : 'Não definido'}</Paragraph>
      ) : (
        <DatePicker
          id={`${id}-date-picker`}
          name={`${id}-date-picker`}
          placeholder="Selecione uma data"
          format="DD/MM/YYYY"
          value={value ? dayjs(value) : null}
          onChange={date => setterFunc(date)}
          style={{ width: '100%' }}
        />
      )}
    </Div>
  );

  DateComponent.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,
    setterFunc: PropTypes.func
  };

  const ResponseDateComponent = useMemo(
    () => (
      <DateComponent
        id="response"
        description="Data da resposta"
        value={responseDate}
        setterFunc={setField('responseDate')}
      />
    ),
    [responseDate, isPrinting]
  );

  const DueDateComponent = useMemo(
    () => (
      <DateComponent
        id="valid"
        description="Prazo de validade da cotação"
        value={dueDate}
        setterFunc={setField('dueDate')}
      />
    ),
    [dueDate, isPrinting]
  );

  const EstimatedDateComponent = useMemo(
    () => (
      <DateComponent
        id="estimated"
        description="Data prevista da entrega"
        value={expectedDate}
        setterFunc={setField('expectedDate')}
      />
    ),
    [expectedDate, isPrinting]
  );

  return (
    <Drawer
      width={900}
      open={open}
      id="quoteSupplierDrawer"
      onClose={onClose}
      title={
        <Div gap={spaces.space2}>
          {data?.supplier?.name}
          {canApprove.includes(data?.status) && !user?.anonymous && (
            <ApproveButton approved={values?.approved} onClick={value => setField('approved')(value)} />
          )}
        </Div>
      }
      padding="0"
      closeIcon={<FontAwesomeIcon icon={faArrowRightFromLine} />}
      footer={readOnly ? null : footer}
      showSubmit={false}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <ComponentToPdf ref={printComponentRef}>
          <Div direction="column" align="flex-start" justify="flex-start" $fullWidth $fullHeight>
            <Div gap={spaces.space1} padding={`${spaces.space3} ${spaces.space2}`}>
              <Paragraph type="small">Status:</Paragraph>
              <Paragraph type="small" color={colors[quoteSupplierStatusMap[status]?.color || 'orange400']}>
                <strong>{quoteSupplierStatusMap[status]?.name || 'Rascunho'}</strong>
              </Paragraph>
            </Div>

            <Divider style={{ margin: 0 }} />

            <FieldContainer padding={`${spaces.space3} ${spaces.space2} ${spaces.space4}`}>
              <Subtitle type="secondary">Resposta da Cotação</Subtitle>
              <Div direction={_isMobile ? 'column' : 'row'} align="flex-start" gap={spaces.space2} $fullWidth>
                {ResponseDateComponent}
                {_isMobile && <Divider style={{ margin: 0 }} />}
                {DueDateComponent}
                {_isMobile && <Divider style={{ margin: 0 }} />}
                {EstimatedDateComponent}
              </Div>
            </FieldContainer>
            <Divider style={{ margin: 0 }} />

            <FieldContainer>
              <Subtitle type="secondary">Itens solicitados</Subtitle>
              {values?.id && (
                <QuoteSupplierItemsTable
                  idQuote={data?.idQuote}
                  quoteSupplier={values}
                  setField={setField}
                  isPrinting={isPrinting}
                  readOnly={readOnly}
                />
              )}
            </FieldContainer>

            <Divider style={{ margin: 0 }} />

            <FieldContainer>
              <Subtitle type="secondary">Pagamento</Subtitle>

              <Div $fullWidth>
                {readOnly ? (
                  <>
                    {paymentInfo?.length ? <RichTextViewer value={paymentInfo} height="240px" /> : <p>Não definido</p>}
                  </>
                ) : (
                  <RichTextEditor
                    id="quote-supplier-payment-info"
                    toolbar={
                      ' bold italic underline strikethrough | forecolor backcolor |' +
                      ' alignleft aligncenter alignright alignjustify | numlist bullist | link table |' +
                      ' h1 h2 h3 h4 h5 h6 | emoticons'
                    }
                    onChange={setField('paymentInfo')}
                    value={paymentInfo}
                    height="160px"
                  />
                )}
              </Div>
            </FieldContainer>

            <Divider style={{ margin: 0 }} />

            <Context
              data={{
                files,
                setFiles,
                observation,
                setObservation: setField('observation'),
                readOnly,
                richTextProps: {
                  id: 'quote-supplier-observation',
                  height: '160px',
                  width: '100%'
                }
              }}
            >
              <ObservationAndFilesComponent observationWidth="100%" padding={`${spaces.space4} ${spaces.space2}`} />
            </Context>
          </Div>
          {openSendToSupplierModal && (
            <SendQuoteToSupplierModal
              idQuoteSupplier={data?.id}
              idQuote={data?.idQuote}
              onClose={() => {
                setOpenSendToSupplierModal(false);
                handleGet();
              }}
              name={data?.supplier?.name}
              email={data?.supplier?.email}
            />
          )}
        </ComponentToPdf>
      )}
    </Drawer>
  );
};

EditQuoteSupplierDrawer.propTypes = {
  open: PropTypes.bool,
  idQuoteSupplier: PropTypes.string,
  handleClose: PropTypes.func,
  isPrint: PropTypes.bool,
  isCustomer: PropTypes.bool
};

export default EditQuoteSupplierDrawer;
