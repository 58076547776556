import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import Dropdown from './DropdownV2';
import { DropdownContainer, StyledMenu } from './DropdownV2.styled';
import DropDownButton from '../Button/DropDownButton';
import useCRUD from '../../_Hooks/useCRUD';
import ConfirmModal from '../Modal/ConfirmModal';
import { ExportTypes, exportToModel } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import SendQuoteToSupplierModal from '../Modal/SendQuoteToSupplierModal';
import OrderLinkedModal from '../Modal/OrderLinkedModal';

const QuoteDropdown = ({
  children,
  idQuote,
  idStatus,
  purchaseQuoteStatus,
  afterSubmit,
  handleExportPdf,
  isView,
  reload = f => f,
  queryString
}) => {
  const { quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const history = useHistory();
  const { isMobile } = useViewport(window.innerWidth);

  const [showModal, setShowModal] = useState();
  const [quoteSuppliersList, setQuoteSuppliersList] = useState([]);

  const { handleCreate, handleUpdate, handleDelete } = useCRUD({
    model: 'quote',
    immediatelyLoadData: false
  });

  const { handleGet: handleGetExportCsv } = useCRUD({
    model: `quote/${idQuote}`,
    immediatelyLoadData: false
  });

  const { handleGet: getQuoteSuppliers } = useCRUD({
    model: `quoteSuppliers`,
    options: { where: { idQuote, status: { ne: quoteSupplierStatus.buy.id } }, include: ['supplier'] },
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (showModal?.modal === 'sendQuoteRequest') getQuoteSuppliers({}).then(setQuoteSuppliersList);
  }, [showModal]);

  const handleExportCsv = () =>
    exportToModel({
      handleGet: handleGetExportCsv,
      exportType: ExportTypes.csv,
      prefixName: 'quoteItems'
    });

  const actions = {
    post: handleCreate,
    put: handleUpdate,
    delete: handleDelete
  };

  const handleCrudClick = (verb, params) => {
    actions[verb]({
      ...params,
      id: idQuote,
      refresh: false,
      displayToast: 'Operação realizada com sucesso!'
    }).then(() => afterSubmit());
  };

  const handleClick = ({ modal, params, redirectTo }) => {
    if (redirectTo) history.push(redirectTo);
    else setShowModal({ modal, params });
  };

  const modal = {
    confirm: () => (
      <ConfirmModal
        onSubmit={() =>
          handleDelete({
            deletePathOptions: `/${idQuote}`,
            displayToast: 'Operação realizada com sucesso!',
            noLoading: true,
            refresh: false
          }).then(resp => !resp?.error && afterSubmit && afterSubmit())
        }
        onClose={() => setShowModal(null)}
        text="Deseja realmente apagar essa cotação?"
        $noPadding
      />
    ),
    sendQuoteRequest: () => (
      <SendQuoteToSupplierModal
        onClose={() => {
          setShowModal(null);
          reload();
        }}
        idQuote={idQuote}
        quoteSupplierList={quoteSuppliersList}
      />
    ),
    orderDetailModal: () => <OrderLinkedModal idQuote={idQuote} onClose={() => setShowModal(null)} />
  };

  const custom = {
    view: (
      <Button
        onClick={() => history.push(`/profissional/gestao-de-compras/cotacoes/visualizar/${idQuote}${queryString}`)}
        text
      >
        Visualizar cotação
      </Button>
    ),
    edit: (
      <Button
        onClick={() => history.push(`/profissional/gestao-de-compras/cotacoes/editar/${idQuote}${queryString}`)}
        text
      >
        Editar dados da cotação
      </Button>
    ),
    exportCsv: (
      <Button text id="export-csv" onClick={() => handleExportCsv()}>
        Exportar CSV
      </Button>
    ),
    exportPdf: (
      <Button text id="export-pdf" onClick={() => handleExportPdf()}>
        Exportar dados da Cotação
      </Button>
    )
  };

  const menu = (
    <StyledMenu $alignLeftOptions>
      {allDropdown
        .quoteAction({
          idQuote,
          idStatus,
          purchaseQuoteStatus,
          isMobile: isMobile(),
          isView
        })
        ?.map(option => (
          <React.Fragment key={`menu-${option.id}`}>
            <Menu.Item key={`menu-item-${option.id}`}>
              {custom[option.button] || (
                <DropDownButton
                  optionData={option}
                  id={option.id}
                  text
                  onClick={() =>
                    option.verb && !option.modal ? handleCrudClick(option.verb, option.params) : handleClick(option)
                  }
                  {...option.buttonParams}
                />
              )}
            </Menu.Item>
          </React.Fragment>
        ))}
    </StyledMenu>
  );

  return (
    <div role="presentation" onClick={e => e.stopPropagation()}>
      {showModal && modal[showModal.modal] ? modal[showModal.modal](showModal.params) : null}
      <DropdownContainer mobileDirection="column">
        <Dropdown slim trigger={['click']} menu={menu} padding={0}>
          {children}
        </Dropdown>
      </DropdownContainer>
    </div>
  );
};

QuoteDropdown.propTypes = {
  idQuote: PropTypes.string,
  idStatus: PropTypes.number,
  children: PropTypes.instanceOf(Object),
  purchaseQuoteStatus: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  handleExportPdf: PropTypes.func,
  isView: PropTypes.bool,
  reload: PropTypes.func,
  queryString: PropTypes.string
};

export default QuoteDropdown;
