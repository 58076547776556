import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidV4 } from 'uuid';
import { toast } from 'react-toastify';
import { Menu } from 'antd';
import { faFolderOpen } from '@fortawesome/pro-duotone-svg-icons';
import Tabs, { TabPane } from '../Tabs/Tabs';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from './Modal';
import SimpleAccordion from '../Accordion/SimpleAccordion';
import { colors, Div, fonts, spaces } from '../../styles/style';
import PurchaseTable from '../Table/PurchaseTable';
import { useContextHook } from '../../contexts/GeneralContext';
import Button from '../Button/Button';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { Paragraph } from '../Text/Text';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import { formatAppropriation, generateMaps, getUniqueItems } from '../../lib/helpers/order';
import AddPurchaseItemForm from '../Form/AddPurchaseItemForm';
import { store } from '../../lib/config/redux-store';
import formatNumber from '../../lib/helpers/formatNumber';
import AddRefurbishApportionmentModal from './AddRefurbishApportionmentModal';
import Dropdown from '../Dropdown/Dropdown';
import ConfirmModal from './ConfirmModal';
import { calculateTotalPercentageSplit, mergeItems } from '../../lib/helpers/payment';
import { apportionmentTotalsColumns } from '../../lib/mapping/TableOrList/apportionmentColumns';
import SimpleTable from '../Table/SimpleTable';
import { TableContainer } from '../Table/CashFlowTable.style';
import NoContent from '../NoContent/NoContent';
import { validatePercentage, validatePurchaseTableItems } from '../../lib/helpers/helper';
import WarningBar from '../Alert/WarningBar';

const Footer = styled(Div)`
  position: absolute;
  bottom: ${spaces.space6};
  width: 100%;
  background-color: ${colors.neutral50};
  justify-content: center;
  gap: ${spaces.space2};
  padding: ${spaces.space1};
`;

const CompleteApportionmentModal = ({
  open,
  refurbishes = {},
  onClose = f => f,
  onSubmit = f => f,
  completeApportionmentList,
  isEdit,
  hasPaidInstallment,
  clearStates
}) => {
  const { setup, authReducer } = store.getState();
  const { user } = authReducer || {};
  const { refurbishItemType = {} } = setup.enums;
  const { itemObjectsMap, itemSettersMap } = useContextHook();
  const [removeRefurbishModal, setRemoveRefurbishModal] = useState(null);
  const { setCreatedItems } = itemSettersMap;
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [addItemType, setAddItemType] = useState(null);
  const [addRefurbishModal, setAddRefurbishModal] = useState(null);
  const [list, setList] = useState([]);
  const [pressedButtonType, setPressedButtonType] = useState(null);
  const [purchaseList, setPurchaseList] = useState(completeApportionmentList || {});
  const [apportionmentTotalsList, setApportionmentTotalsList] = useState([]);
  const [totals, setTotals] = useState({});
  const [openAllRefurbishes, setOpenAllRefurbishes] = useState(false);
  const [invalidPaymentItems, setInvalidPaymentItems] = useState({});
  const [touched, setTouched] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [itemsOnListMap, setItemsOnListMap] = useState({});

  const tabEnum = {
    list: '1',
    totals: '2'
  };

  const [tabView, setTabView] = useState(tabEnum.list);

  const { handleGet } = useCRUD({
    model: 'refurbish',
    immediatelyLoadData: false
  });

  useEffect(() => {
    setTotals(calculateTotalPercentageSplit(purchaseList));
  }, [purchaseList]);

  useEffect(() => {
    if (tabView === tabEnum.totals)
      setApportionmentTotalsList(mergeItems({ items: purchaseList, mid: true, refurbishesList: list }));
  }, [tabView]);

  const handleLoadRefurbishes = (ids, init) => {
    !init && setTouched(true);
    return handleGet({ refetchOptions: { attributes: ['id', 'name'], where: { id: ids } } }).then(refurbishesList => {
      setList(prev => Array.from(new Set([...prev, ...refurbishesList])));

      if (init && completeApportionmentList) return null;
      return setPurchaseList(prev => {
        const updatedList = { ...prev };
        refurbishesList.forEach(item => {
          updatedList[item.id] = mergeItems({ items: purchaseList, isNew: !init }) || [];
        });
        return updatedList;
      });
    });
  };

  useEffect(() => {
    handleLoadRefurbishes(Object.keys(refurbishes), true);
  }, []);

  const handleSetList = refurbishId => setFunc => {
    setTouched(true);
    const result = setFunc(purchaseList[refurbishId]);
    setPurchaseList(prev => ({ ...prev, [refurbishId]: result }));
  };

  const handleAddItem = (item, includeItemLevel) => {
    setTouched(true);
    const cleanPrice = formatNumber(item.price);
    const cleanQuantity = formatNumber(item.quantity);
    const _id = uuidV4();
    const appropriations = item?.parentList?.length
      ? item?.parentList?.map(parent => formatAppropriation({ appropriation: parent, idParent: _id, parent: item }))
      : null;

    setAddItemType(null);
    list?.forEach(refurbish => {
      setCreatedItems(prev => ({
        ...prev,
        [refurbish.id]: { ...(prev?.[refurbish.id] ? prev[refurbish.id] : {}), [_id]: true }
      }));
    });

    return {
      ...item,
      price: cleanPrice || 0,
      quantity: cleanQuantity || 0,
      id: _id,
      ...(!item?.idItem && { idRefurbishItem: item.id }),
      percentage: 1,
      ...(includeItemLevel && { paymentItemLevels: appropriations }),
      ...(!includeItemLevel && { parentList: null }),
      purchaseDate: new Date()
    };
  };

  const handleAddAll = data => {
    setTouched(true);
    setPurchaseList(prev => {
      const updatedPrev = {};
      Object.keys(prev).forEach(key => {
        updatedPrev[key] = [...(prev[key] || []), handleAddItem(data)];
      });
      return updatedPrev;
    });
    setAddItemType(null);
    setPressedButtonType(null);
    setOpenAllRefurbishes(true);
  };

  const handleSubmitCatalog = ({ itemData, ...newItem }, idReference) => {
    setTouched(true);
    const _list = purchaseList?.[idReference] || [];
    if (newItem?.childId) {
      const index = _list?.findIndex(i =>
        i?.idItem ? i?.idItem === newItem?.childId : i?.idRefurbishItem === newItem?.childId
      );

      if (index > -1) {
        toast.error(`Item já existe na lista`);
        return;

        // setPurchaseList(prev => ({
        //   ...prev,
        //   [idReference]: submitCatalog({
        //     newItem,
        //     childrenColumnName: 'paymentItemLevels',
        //     index,
        //     type: 'purchase',
        //     prev,
        //     setCreatedLevels
        //   })
        // }));
        // return;
      }

      setPurchaseList(prev => ({
        ...prev,
        ...Object.keys(prev).reduce((acc, key) => {
          acc[key] = [...prev[key], handleAddItem(itemData, true)];
          return acc;
        }, {}),

        ...(idReference
          ? {
              [idReference]: [
                ...(prev?.[idReference] || []),
                handleAddItem({ ...itemData, parentList: [newItem] }, true)
              ]
            }
          : {})
      }));
      setOpenAllRefurbishes(true);
      return;
    }
    const index = _list?.findIndex(
      i => (newItem?.idItem && i?.idItem === newItem?.idItem) || (newItem?.id && i?.idRefurbishItem === newItem?.id)
    );

    if (index > -1) {
      toast.error(`Item já existe na lista`);
      return;
    }
    setPurchaseList(prev => ({
      ...prev,
      ...Object.keys(prev).reduce((acc, key) => {
        acc[key] = [...prev[key], handleAddItem(newItem)];
        return acc;
      }, {}),

      ...(idReference ? { [idReference]: [...(prev?.[idReference] || []), handleAddItem(newItem, true)] } : {})
    }));
    setOpenAllRefurbishes(true);
  };

  const onItemClick = _type => {
    if (addItemType) return;
    setAddItemType(_type);
  };

  const addNewProject = newRefurbishes => {
    handleLoadRefurbishes(newRefurbishes.map(r => r?.value || r?.id));
  };

  const removeRefurbish = id => {
    setTouched(true);
    setList(prev => prev.filter(item => item.id !== id));
    setPurchaseList(prev => {
      const { [id]: _, ...updatedList } = prev;
      return updatedList;
    });
  };
  const handleConfirm = () => {
    const hasErrorInItems = [];
    let hasErrorAppropriation = false;
    Object.entries(purchaseList).forEach(([key, value]) => {
      const validateString = validatePurchaseTableItems({
        values: { paymentItems: value },
        setInvalidItems: stringError => setInvalidPaymentItems(prev => ({ ...prev, [key]: stringError }))
      });
      if (!validatePercentage(value)) hasErrorAppropriation = true;
      if (validateString) return;
      hasErrorInItems.push(validateString);
    });
    if (hasErrorInItems.length) {
      toast.error('Verifique os erros dos itens antes de salvar!');
      return;
    }

    if (hasErrorAppropriation) {
      toast.error('A soma das apropriações deve ser igual a 100%.');
      return;
    }
    onSubmit(purchaseList, mergeItems({ items: purchaseList, mid: true, refurbishesList: list }), totals, list);
  };

  const handleCancel = () => {
    if (!touched) {
      onClose();
      clearStates();
      return;
    }
    setShowConfirmModal(true);
  };

  const RefurbishPurchase = refurbish => {
    return (
      <SimpleAccordion
        initOpen
        forceOpen={openAllRefurbishes}
        setForceOpen={setOpenAllRefurbishes}
        title={` ${refurbish?.name}`}
        extraHeaderComponent={
          <Div gap={spaces.space1}>
            <Paragraph>Total</Paragraph>
            <Paragraph weight={fonts.weight700}>
              {formatCurrency(totals?.[0]?.[refurbish?.id]?.value || 0, {
                currencySymbol: 'R$'
              })}
            </Paragraph>
            {!hasPaidInstallment ? (
              <Dropdown
                slim
                trigger={['click']}
                disabled={!!addItemType}
                menu={
                  <Menu.Item>
                    <Button
                      text
                      id="removeRefurbish"
                      type="danger"
                      onClick={e => {
                        setRemoveRefurbishModal(refurbish?.id);
                        e.stopPropagation();
                      }}
                    >
                      Excluir
                    </Button>
                  </Menu.Item>
                }
              >
                <FontAwesomeIcon
                  onClick={e => e.stopPropagation()}
                  color={colors.primary500}
                  icon={faEllipsisV}
                  id={`${refurbish.id}-dropdown-apportionment`}
                />
              </Dropdown>
            ) : null}
          </Div>
        }
      >
        <PurchaseTable
          list={purchaseList[refurbish.id] || []}
          idRefurbish={refurbish.id}
          paymentView
          itemObjectsMap={itemObjectsMap}
          itemSettersMap={itemSettersMap}
          childrenColumnName="paymentItemLevels"
          parentColumnName="paymentItems"
          setList={handleSetList(refurbish?.id)}
          height="300px"
          hideAddButtons
          splitView
          isEdit={isEdit}
          noContentText={`Busque ou crie novos produtos e serviços.
            Eles serão adicionados em todos os projetos do rateio.`}
          bugReload
          invalidPaymentItems={invalidPaymentItems?.[refurbish?.id] || ''}
          setInvalidPaymentItems={resetString =>
            setInvalidPaymentItems(prev => ({ ...prev, [refurbish?.id]: resetString }))
          }
          readOnly={hasPaidInstallment}
        />
      </SimpleAccordion>
    );
  };

  useEffect(() => {
    const uniqueList = getUniqueItems(purchaseList || {});
    const { newItemsOnListMap } = generateMaps(uniqueList, 'paymentItemLevels');

    setItemsOnListMap(newItemsOnListMap);
  }, [purchaseList]);

  return (
    <>
      <Modal
        height="90%"
        noPadding
        onClose={() => handleCancel()}
        title="Rateio de itens por projeto"
        width={1178}
        padding={0}
        auxText={
          <>
            Total geral: <b>{formatCurrency(totals?.[1] || 0, { currencySymbol: 'R$' })}</b>
          </>
        }
        open={open}
        onSubmit={() => handleConfirm()}
      >
        <Tabs
          tabBarExtraContent={
            tabView === tabEnum.list && !hasPaidInstallment ? (
              <Div justify="end">
                <Button onClick={() => setAddRefurbishModal(true)} type="primary" ghost>
                  + Projeto
                </Button>
              </Div>
            ) : null
          }
          tabBarStyle={{
            paddingTop: 0,
            borderBottom: `1px solid ${colors.neutral100}`,
            position: 'sticky',
            top: 0,
            zIndex: 100
          }}
          onTabClick={e => {
            setTabView(e);
          }}
        >
          <TabPane key={tabEnum.list} tab="Itens por projeto">
            <Div padding={`${spaces.space2} ${spaces.space2} ${spaces.space6} ${spaces.space2}`} direction="column">
              {list.length ? (
                <>
                  {hasPaidInstallment ? (
                    <WarningBar
                      warning="A edição dos itens está desabilitada porque alguma parcela já foi paga."
                      width="100%"
                      margin={`0 0 ${spaces.space2} 0`}
                      type="light"
                    />
                  ) : null}
                  {list?.map(r => RefurbishPurchase(r))}
                </>
              ) : (
                <NoContent
                  title="Você não tem projetos cadastrados no seu rateio"
                  description={`Adicione um projeto e
                    tenha acesso aos itens, valores, quantidades, unidades, apropriações e mais!`}
                  renderTitle={false}
                  icon={faFolderOpen}
                  buttonTitle="Projeto"
                  onClick={() => setAddRefurbishModal(true)}
                />
              )}
            </Div>

            <Footer>
              <Div direction="column" $fullWidth>
                {addItemType ? (
                  <AddPurchaseItemForm
                    idCompany={user?.idCompany}
                    handleSubmit={handleAddAll}
                    onClose={() => {
                      setAddItemType(null);
                      setPressedButtonType(null);
                    }}
                    type={addItemType}
                    paymentView
                  />
                ) : null}
                {list.length && !hasPaidInstallment ? (
                  <Div>
                    <Button
                      id="add-apportionment-item"
                      text={pressedButtonType !== 'search'}
                      type="primary"
                      onClick={() => {
                        if (addItemType) return;
                        setPressedButtonType('search');
                        !addItemType && setShowCatalogDrawer(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                      Buscar item
                    </Button>
                    <Button
                      id="add-product-purchase-item"
                      text={pressedButtonType !== 'addProduct'}
                      type="primary"
                      onClick={() => {
                        if (addItemType) return;
                        setPressedButtonType('addProduct');
                        !addItemType && onItemClick(refurbishItemType.product);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Produto
                    </Button>
                    <Button
                      id="add-service-purchase-item"
                      text={pressedButtonType !== 'addLabor'}
                      type="primary"
                      onClick={() => {
                        if (addItemType) return;
                        setPressedButtonType('addLabor');
                        !addItemType && onItemClick(refurbishItemType.labor);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      Serviço
                    </Button>
                  </Div>
                ) : null}
              </Div>
            </Footer>
          </TabPane>
          <TabPane key={tabEnum.totals} tab="Totais dos itens">
            <TableContainer padding={spaces.space2}>
              <SimpleTable
                columns={apportionmentTotalsColumns()}
                data={apportionmentTotalsList}
                isExpandable={false}
                $borderBottom
              />
            </TableContainer>
          </TabPane>
        </Tabs>
        {showCatalogDrawer && (
          <CatalogDrawer
            open
            onClose={() => {
              setShowCatalogDrawer(false);
              setPressedButtonType(null);
            }}
            createLibrary
            onSubmit={(input, _, idReference) => handleSubmitCatalog(input, idReference)}
            tabsToOpen={[-1, 0, 1]}
            idReference={list[0].id}
            refurbishesOptions={list?.map(r => r.id)}
            itemsOnListMap={itemsOnListMap}
            subtitle={
              showCatalogDrawer?.isLink
                ? 'Busque o item da biblioteca para vincular com o item selecionado'
                : 'Busque pelo produto ou serviço que deseja adicionar'
            }
            fixedType={refurbishItemType.productLabor}
          />
        )}
      </Modal>
      {addRefurbishModal ? (
        <AddRefurbishApportionmentModal
          onSubmit={addNewProject}
          onClose={() => setAddRefurbishModal(false)}
          refurbishList={list?.map(r => r.id)}
        />
      ) : null}
      {removeRefurbishModal ? (
        <ConfirmModal
          alertInfo={list?.length === 1 ? 'Essa operação não pode ser desfeita.' : null}
          text={
            list?.length === 1
              ? 'Ao remover o projeto todos os itens do rateio serão perdidos.'
              : 'Deseja realmente remover esse projeto do rateio?'
          }
          onSubmit={() => removeRefurbish(removeRefurbishModal)}
          onClose={() => setRemoveRefurbishModal(null)}
        />
      ) : null}
      {showConfirmModal ? (
        <ConfirmModal
          title="Alterações não salvas"
          alertInfo="Essa operação não pode ser desfeita."
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar"
          showDiscardButton
          onSubmit={() => handleConfirm()}
          onClose={(_, opts) => {
            setShowConfirmModal(false);
            if (opts?.discard) {
              onClose(true);
              clearStates();
            }
          }}
        />
      ) : null}
    </>
  );
};

CompleteApportionmentModal.propTypes = {
  open: PropTypes.bool,
  refurbishes: PropTypes.instanceOf(Array),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  completeApportionmentList: PropTypes.instanceOf(Object),
  isEdit: PropTypes.bool,
  hasPaidInstallment: PropTypes.bool,
  clearStates: PropTypes.func
};

export default CompleteApportionmentModal;
