import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import useCRUD from '../../../_Hooks/useCRUD';

import Steps from '../../Settings/Steps';
import CenteredLoader from '../../../components/Loader/CenteredLoader';
import { StepBox, StepsContainer, StepsContent } from './Onboarding.styled';
import EngConsTab from '../../../components/Images/eng-cons-tab.svg';
import ArchitectureTab from '../../../components/Images/architecture-tab.svg';
import { Subtitle, Title } from '../../../components/Text/Text';

const StepsSettings = ({ data }) => {
  const { companyTypesCategories } = useSelector(state => state.setup.systemData);
  const type = Object.entries(companyTypesCategories).find(([, types]) => types.includes(data?.idType))?.[0];

  const imagesMap = {
    architecture: ArchitectureTab,
    engCons: EngConsTab
  };

  const { loading, handleCreate } = useCRUD({
    model: 'step',
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (data?.idType && type) {
      handleCreate({ values: { type }, postPathOptions: '/fill-onboarding', refresh: false });
    }
  }, []);

  return loading ? (
    <CenteredLoader />
  ) : (
    <StepsContainer>
      <div className="stepColumn">
        <StepsContent>
          <Title id="onboarding-page-title">Configure os status</Title>
          <Subtitle className="stepsSubtitle">
            Organize {type === 'architecture' ? 'seus projetos' : 'suas obras'} e fique por dentro de tudo o que está
            acontecendo em sua empresa. Defina os status do jeito que precisar, de acordo com seu negócio.
          </Subtitle>

          <StepBox>
            <Steps hideHeader skipConfirmation isOnboarding />
          </StepBox>
        </StepsContent>
      </div>
      <div className="secondColumn">
        <img src={imagesMap[type]} alt="Imagem" />
      </div>
    </StepsContainer>
  );
};

StepsSettings.propTypes = {
  data: PropTypes.instanceOf(Object)
};

export default StepsSettings;
