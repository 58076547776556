import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, spaces } from '../../styles/style';
import { Description } from '../Text/Text';

const FlatTagStyle = styled.div`
  border-radius: ${spaces.space2};
  background-color: ${colors.neutral100};
  display: flex;
  align-items: center;
  padding: ${spaces.space0} ${spaces.space1};

  &.success {
    background-color: ${colors.green100};
    span {
      color: ${colors.green500};
    }
  }

  &.primary {
    background-color: ${colors.primary50};
    span {
      color: ${colors.primary400};
    }
  }
`;

const FlatTag = ({ className, value }) => {
  return (
    <FlatTagStyle className={className}>
      <Description>{value}</Description>
    </FlatTagStyle>
  );
};

FlatTag.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string
};

export default FlatTag;
