import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { Menu } from 'antd';
import DropdownV2 from '../Dropdown/DropdownV2';
import Button from './Button';
import useViewport from '../../_Hooks/useViewport';
import { colors, spaces } from '../../styles/style';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import { StyledMenu } from '../Dropdown/DropdownV2.styled';
import DropDownButton from './DropDownButton';

const AddTableButtonV2 = ({ onSubmit = f => f, ...props }) => {
  const { isMobile } = useViewport(window.innerWidth);

  const menu = (
    <StyledMenu $alignLeftOptions={false}>
      {allDropdown.addRefurbishItems(isMobile())?.map(option => {
        return (
          <React.Fragment key={`menu${option.label}`}>
            <Menu.Item key={`menuItem${option.label}`}>
              <DropDownButton optionData={option} id={option.id} text onClick={() => onSubmit(option)} />
            </Menu.Item>
          </React.Fragment>
        );
      })}
    </StyledMenu>
  );

  return (
    <DropdownV2 slim trigger={['click']} menu={menu} {...props}>
      <Button
        id="id-add-item"
        size="small"
        type="primary"
        shape="round"
        roundPadding={`0 ${spaces.space2} 0 ${spaces.space0}`}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{
            margin: `0 ${spaces.space1} 0 2px`,
            color: colors.white
          }}
          size="lg"
        />
        ITEM
      </Button>
    </DropdownV2>
  );
};

AddTableButtonV2.propTypes = {
  onSubmit: PropTypes.func
};

export default AddTableButtonV2;
