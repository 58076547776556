import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AboutContent, AboutYouCardGroup, Card, TextSelectContainer } from './Onboarding.styled';
import Select from '../../../components/Select/Select';
import { Subtitle, Paragraph, Title } from '../../../components/Text/Text';
import { fonts } from '../../../styles/style';

const AboutYou = ({ data, onChange }) => {
  const [_data, setData] = useState(data);

  const handleChange = ({ onboarding, idType }) => {
    const newData = { ..._data, idType: idType || _data.idType, onboarding: { ..._data?.onboarding, ...onboarding } };
    setData(newData);
    onChange(newData);
  };

  return (
    <AboutContent>
      <Title id="onboarding-page-title">Vamos configurar sua conta</Title>
      <TextSelectContainer>
        <Subtitle>Como você descreve o seu negócio?</Subtitle>
        <Select
          id="configOption"
          name="configOption"
          type="select"
          value={_data?.onboarding?.configDescription || data?.onboarding?.configDescription}
          placeholder="Escolha uma opção"
          onChange={e => handleChange({ idType: e, onboarding: { configDescription: e } })}
          model="companyType"
        />
      </TextSelectContainer>
      <Subtitle weight={fonts.weight600}>Qual sua familiaridade com ferramentas de gestão?</Subtitle>
      <AboutYouCardGroup>
        <Card
          onClick={() => handleChange({ onboarding: { configExperience: 1 } })}
          $isSelected={_data?.onboarding?.configExperience === 1}
        >
          <Paragraph>Não tenho experiência</Paragraph>
          <Paragraph className="info-text">Estou começando agora.</Paragraph>
        </Card>
        <Card
          onClick={() => handleChange({ onboarding: { configExperience: 2 } })}
          $isSelected={_data?.onboarding?.configExperience === 2}
          middle
        >
          <Paragraph>Tenho pouco conhecimento</Paragraph>
          <Paragraph className="info-text">Gostaria de ajuda.</Paragraph>
        </Card>
        <Card
          onClick={() => handleChange({ onboarding: { configExperience: 3 } })}
          $isSelected={_data?.onboarding?.configExperience === 3}
        >
          <Paragraph>Não tenho dificuldades</Paragraph>
          <Paragraph className="info-text">Não preciso de ajuda.</Paragraph>
        </Card>
      </AboutYouCardGroup>
    </AboutContent>
  );
};

AboutYou.propTypes = {
  onChange: PropTypes.func,
  data: PropTypes.instanceOf(Object)
};

export default AboutYou;
