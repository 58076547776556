import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons';
import { CurrencyInput } from 'react-currency-mask';
import { colors, fonts, spaces } from '../../styles/style';
import TooltipIcon from '../Tooltip/TooltipIcon';
import { EditContainer, StyledDiv } from './EditableInput.style';
import formatNumber from '../../lib/helpers/formatNumber';
import { Paragraph } from '../Text/Text';

const EditableInput = ({
  icon = false,
  value,
  onChange = () => {},
  noWrap = false,
  disabled,
  initEdit,
  map,
  withEditButton,
  onClick,
  onBlur = f => f,
  rowId,
  element,
  unit,
  placeholder,
  id,
  type,
  forceShow,
  size,
  textStyleProps,
  InputStyle = { fontSize: fonts.fontSm },
  showUnitWhenNotEmpty,
  propsParagraph,
  ...props
}) => {
  const [_value, setValue] = useState(value || '-');
  const [edit, setEdit] = useState(initEdit);
  const ref = useRef();

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (rowId || element) {
      const expandedRows = document.getElementsByClassName('expanded-row') || [];
      const _element = element || document.querySelector(`[data-row-key="${rowId}"]`);
      if (edit && _element) {
        _element.setAttribute('draggable', false);
        Array.from(expandedRows).forEach(item => item.setAttribute('draggable', false));
      } else if (_element) {
        _element.setAttribute('draggable', true);
        Array.from(expandedRows).forEach(item => item.setAttribute('draggable', true));
      }
    }
  }, [edit]);

  const isPromise = returnOnchange => {
    return !!(typeof returnOnchange === 'object' && typeof returnOnchange?.then === 'function');
  };

  const handleBlur = ({ target: { value: newValue } = {} }) => {
    if ((edit || forceShow) && value !== newValue) {
      let _newValue = newValue;
      if (map) {
        _newValue = map(newValue);
        setValue(_newValue);
      }
      const responseOnChange = onChange(_newValue);
      if (isPromise(responseOnChange)) {
        responseOnChange.then(resp => {
          if (resp?.error) setValue(value);
        });
      }
    }

    setEdit(false);
    onBlur();
  };

  const handleKey = e => {
    if (e.key === 'Escape') {
      setEdit(false);
      setValue(value);
    }
    if (e.key === 'Tab') {
      handleBlur({ target: { value: _value } });
    }
    if (e.key === 'Enter') {
      handleBlur({ target: { value: _value } });
    }
  };

  if ((!disabled && edit) || forceShow) {
    return type === 'currency' ? (
      <CurrencyInput
        ref={ref}
        value={formatNumber(_value)}
        onChangeValue={(_, __, maskedValue) => setValue(maskedValue)}
        onBlur={handleBlur}
        InputElement={
          <Input
            id={id || `task-name-${rowId}`}
            style={InputStyle}
            autoFocus={!forceShow}
            status={forceShow && props.withError && 'error'}
            placeholder={forceShow ? placeholder : ''}
            disabled={disabled}
            onKeyDown={handleKey}
            onClick={e => e.stopPropagation()}
            size={size}
          />
        }
      />
    ) : (
      <Input
        id={id || `task-name-${rowId}`}
        style={InputStyle}
        autoFocus={!forceShow}
        status={forceShow && props.withError && 'error'}
        placeholder={forceShow ? placeholder : ''}
        value={_value}
        onKeyDown={handleKey}
        onChange={e => setValue(e.target.value)}
        onBlur={handleBlur}
        onClick={e => e.stopPropagation()}
        disabled={disabled}
        size={size}
      />
    );
  }

  if (withEditButton) {
    return (
      <EditContainer onClick={onClick}>
        <StyledDiv role="presentation" cursor="pointer" disabled noWrap={noWrap} {...props}>
          {_value || '-'}
        </StyledDiv>
        {!disabled && (
          <TooltipIcon
            style={{ height: spaces.space2, width: spaces.space2 }}
            className="renameItem showHover"
            text="Renomear"
            icon={faPenToSquare}
            iconColor={colors.neutral600}
            onClick={e => {
              setEdit(true);
              e.stopPropagation();
            }}
          />
        )}
      </EditContainer>
    );
  }

  const valueToShow = _value || placeholder || '-';

  return (
    <StyledDiv
      id={id}
      role="presentation"
      disabled={disabled}
      noWrap={noWrap}
      onClick={e => {
        e.stopPropagation();
        setEdit(true);
      }}
      {...props}
    >
      {textStyleProps || propsParagraph ? (
        <Paragraph style={textStyleProps || {}} {...propsParagraph}>
          {valueToShow}
        </Paragraph>
      ) : (
        valueToShow
      )}
      {unit && (!showUnitWhenNotEmpty || _value) ? ` ${unit}` : ''}
      {icon && <FontAwesomeIcon icon={faPencilAlt} size="xs" />}
    </StyledDiv>
  );
};

EditableInput.propTypes = {
  icon: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  noWrap: PropTypes.bool,
  disabled: PropTypes.bool,
  initEdit: PropTypes.bool,
  map: PropTypes.func,
  withEditButton: PropTypes.bool,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  rowId: PropTypes.number,
  element: PropTypes.instanceOf(Object),
  unit: PropTypes.string,
  placeholder: PropTypes.string,
  inputStyle: PropTypes.instanceOf(Object),
  id: PropTypes.string,
  type: PropTypes.string,
  forceShow: PropTypes.bool,
  withError: PropTypes.bool,
  size: PropTypes.string,
  textStyleProps: PropTypes.instanceOf(Object),
  showUnitWhenNotEmpty: PropTypes.bool,
  InputStyle: PropTypes.instanceOf(Object),
  propsParagraph: PropTypes.instanceOf(Object)
};

export default EditableInput;
