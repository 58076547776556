import * as Yup from 'yup';

import AboutYou from './AboutYou';
import MainChallenges from './MainChallenges';
import CompanyDetails from './CompanyDetails';
import StepsSettings from './StepsSettings';
import FirstProject from './FirstProject';

export default {
  'sobre-voce': {
    Content: AboutYou,
    nextStep: 'principais-desafios',
    progressionBar: '20%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        configDescription: Yup.number().required(),
        configExperience: Yup.number().required()
      })
    })
  },
  'principais-desafios': {
    Content: MainChallenges,
    previousStep: 'sobre-voce',
    nextStep: 'mais-detalhes',
    progressionBar: '40%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        mainChallenge: Yup.array(Yup.number()).required()
      })
    })
  },
  'mais-detalhes': {
    Content: CompanyDetails,
    previousStep: 'principais-desafios',
    nextStep: 'configure-status',
    progressionBar: '60%',
    schema: Yup.object().shape({
      onboarding: Yup.object().shape({
        services: Yup.number().required(),
        projectCount: Yup.number().required()
      })
    })
  },
  'configure-status': {
    Content: StepsSettings,
    progressionBar: '75%',
    previousStep: 'mais-detalhes',
    nextStep: 'primeiro-projeto'
  },
  'primeiro-projeto': {
    Content: FirstProject,
    progressionBar: '90%',
    hideFooter: true,
    previousStep: 'configure-status'
  }
};
