import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { budgetCompositionMapping, itemMapping, itemSchema } from '../../lib/mapping/Form/itemSchema';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import Box from '../Box/Box';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import Form from '../Form/Form';
import CenteredLoader from '../Loader/CenteredLoader';
import { Line } from './Composition.styled';
import CompositionItems from './CompositionItems';
import { radius, spaces } from '../../styles/style';
import ConfirmModal from '../Modal/ConfirmModal';
import { getCatalogDrawerDisplayToast, getObjectWithSelectedFields } from '../../lib/helpers/helper';
import ConfirmModalAlertInfoBuilder from '../Modal/ConfirmModalAlertInfoBuilder';
import openNewTab from '../../lib/helpers/openNewTab';
import formatNumber from '../../lib/helpers/formatNumber';
import formatNumberWithDynamicDecimals from '../../lib/helpers/formatNumberWithDynamicDecimals';

const childrenModel = {
  item: 'item',
  'refurbish-items': 'refurbishItems',
  'template-item': 'templateItem'
};

const Composition = ({
  id,
  onChangeData,
  model = 'refurbish-items',
  noPadding,
  setExtraDrawerStyle = f => f,
  displayButtons = true,
  idReference,
  onClose = f => f,
  selectedTab,
  observation,
  files,
  secondDrawer,
  setSecondDrawer = f => f,
  groupedReference,
  includeFiles,
  initialValues,
  renderColumns,
  readOnly,
  isTemplate,
  idCompany,
  options,
  setReload,
  isLibrary,
  externalTriggerProps = {},
  onlyLabor,
  parentIsComposition,
  ...props
}) => {
  const { refurbishItemType: itemTypeEnum, userType, refurbishItemStatus } = useSelector(state => state.setup.enums);
  const {
    triggerSubmit,
    setTriggerSubmit,
    triggerLink,
    setTriggerLink,
    refreshComposition,
    setRefreshComposition,
    setTouched,
    touched
  } = externalTriggerProps;

  const [listItems, setListItems] = useState([]);
  const { isMobile, isUltraWide } = useViewport(window.innerWidth);
  const { user } = useSelector(state => state.authReducer);
  const isCustomer = user.userType === userType.customer.value;
  const [checkedValue, setCheckedValue] = useState(true);
  const widthToComposition = isUltraWide() ? 440 : 336;

  const [isEdit, setIsEdit] = useState();
  const [linkedItem, setLinkedItem] = useState(null);
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(false);
  const [isEditableFormValid, setIsEditableFormValid] = useState(true);

  const [formData, setFormData] = useState({});

  const { data, loading, handleGet, handleCreate, handleUpdate } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const handleSetLinkedItem = modelItem => {
    if (modelItem?.item) setLinkedItem(modelItem?.item);
    else if (modelItem?.externalBaseCode) setLinkedItem(modelItem);
    else setLinkedItem(null);
  };

  const handleLoad = (generateLoading = true) => {
    const isModelItem = model === 'item';
    if (id) {
      handleGet({
        refetchPathOptions: id ? `/${id}` : '',
        refetchOptions: options || {
          where: { ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }), idCompany },
          include: [
            'supplier',
            'units',
            'class',
            ...(includeFiles ? ['files'] : []),
            {
              model: childrenModel[model],
              as: 'children',
              include: [
                'units',
                ...(!isModelItem
                  ? [
                      {
                        attributes: ['id', 'code', 'name', 'images'],
                        model: 'Item',
                        as: 'item',
                        include: [{ attributes: ['id', 'name'], model: 'Unit', as: 'units' }]
                      }
                    ]
                  : [])
              ]
            },
            ...(!isModelItem
              ? [
                  {
                    model: 'Item',
                    as: 'item',
                    include: [
                      { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
                      { attributes: ['id', 'name', 'code'], model: 'ItemClass', as: 'class' }
                    ]
                  }
                ]
              : [])
          ],
          order: isModelItem
            ? '"children->relationItem"."createdAt"'
            : [['children', 'createdAt', 'asc'], [['children', 'name', 'asc']]]
        },
        generateLoading
      });
      setIsEdit(true);
    } else setIsEdit(false);
  };

  const _handleCreateItems = childValues => {
    const { idCostCenter, idRefurbishGroup, idSupplier, idSearch, ..._values } = childValues;

    const values = {
      idSupplier: idSupplier || undefined,
      idCostCenter: idCostCenter || undefined,
      idRefurbishGroup: idRefurbishGroup || undefined,
      ..._values,
      ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
      idParent: id,
      ...(idSearch ? { idSearch } : { idItem: childValues?.id })
    };
    return handleCreate({
      values,
      refresh: false,
      noLoading: true
    }).then(res => {
      if (!res?.error) {
        toast.success('Item adicionado na composição com sucesso.');
        handleLoad(false);
      }
    });
  };

  useEffect(() => {
    handleLoad();
  }, [id]);

  useEffect(() => {
    setListItems(data?.children || []);
    handleSetLinkedItem(data);
    onChangeData && onChangeData(data);
    if (data) {
      setFormData({
        original: getObjectWithSelectedFields(data, [
          ...(!parentIsComposition ? ['idParent'] : []),
          'quantity',
          'idSupplier',
          'idCostCenter',
          'idRefurbishGroup',
          'description',
          'status'
        ]),
        labor: !linkedItem ? getObjectWithSelectedFields(data, ['code', 'name', 'autoCode', 'idClass', 'idUnit']) : {}
      });
    }
  }, [data]);

  useEffect(() => {
    if (refreshComposition) {
      handleLoad();
      setRefreshComposition(false);
    }
  }, [refreshComposition]);

  const handleSubmit = () => {
    if (!isEditableFormValid) {
      setTriggerSubmit(false);
      return;
    }
    const values = getObjectWithSelectedFields(formData?.original, [
      ...(!parentIsComposition ? ['idParent'] : []),
      'quantity',
      'idSupplier',
      'idCostCenter',
      'idRefurbishGroup',
      'description',
      'status'
    ]);
    const _labor = !linkedItem
      ? getObjectWithSelectedFields(formData?.labor, ['code', 'name', 'autoCode', 'idClass', 'idUnit'])
      : {};

    if (values.quantity != null) {
      values.quantity = formatNumber(values?.quantity);
    }

    if (!isEdit) {
      handleCreate({
        values: {
          ...initialValues,
          ...values,
          ..._labor,
          ...groupedReference,
          ...(initialValues?.idParent !== values?.idParent && { order: null }),
          idUnit: _labor?.idUnit || 1,
          ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
          type: itemTypeEnum.composition,
          autoCode: checkedValue
        },
        refresh: false,
        noLoading: false
      }).then(res => {
        if (!res?.error) {
          toast.success('Composição criada com sucesso.');
          setTriggerSubmit(false);
          onChangeData && onChangeData(res);
          setReload(true);
          parentIsComposition && onClose(res);
        }
      });
    } else {
      handleUpdate({
        updatePathOptions: `/${id}`,
        values:
          selectedTab !== '0'
            ? {
                ...values,
                ..._labor,
                price: undefined,
                ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
                observation,
                files,
                ...(data?.idParent !== values?.idParent && { order: null }),
                ...(data.status !== values.status &&
                  values.status === refurbishItemStatus.approved.value && { isApproved: true })
              }
            : {
                ...values,
                ..._labor,
                ...(isTemplate ? { idTemplate: idReference } : { idRefurbish: idReference }),
                image: null,
                price: undefined,
                observation,
                files,
                ...(data?.idParent !== values?.idParent && { order: null }),
                ...(data?.status !== values?.status &&
                  values?.status === refurbishItemStatus.approved.value && { isApproved: true })
              },
        noLoading: true,
        refresh: false
      }).then(res => {
        if (!res.error) {
          toast.success('Composição atualizada com sucesso.');
          const resp = res?.rows?.[0];
          onChangeData && onChangeData(prev => ({ ...prev, name: resp?.name || prev?.name }), triggerSubmit?.goBack);
          setTriggerSubmit(false);
          setTouched(false);
          if (!triggerSubmit?.goBack) handleLoad();
        }
      });
    }
  };

  const _setFormData = source => values => {
    if (values) setFormData(prev => ({ ...prev, [source]: { ...prev?.[source], ...values } }));
  };

  const handleSaveLink = itemToSave => {
    handleUpdate({
      updatePathOptions: `/${id}${itemToSave ? '/setChildrenFromItem' : ''}`,
      values: { idItem: itemToSave?.id || null, externalBaseCode: itemToSave?.externalBaseCode || null },
      refresh: false,
      noLoading: true,
      displayToast: getCatalogDrawerDisplayToast(itemToSave)
    }).then(resp => {
      if (!resp?.error) {
        handleLoad();
        setLinkedItem(itemToSave);
        if (!itemToSave) _setFormData('labor')(data);
      }
    });
  };

  const handleConfirmModal = confirmModalType => {
    const confirmModalPropsMap = {
      unlinkItem: {
        title: 'Desvincular item',
        alertInfo: 'Ao desvincular esse item, ele perderá a relação com a Biblioteca',
        preInfoDescription: 'Deseja concluir ação?',
        onSubmit: () => handleSaveLink(null)
      },
      editLibraryItem: {
        title: 'Todas as ocorrências serão editadas',
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao editar essa composição, os
            seguintes campos de todas as aparições desse item serão alteradas:`}
            afterAlertList={['Nome do item', 'Código', 'Unidade', 'Classe']}
          />
        ),
        preInfoDescription: 'Deseja confirmar?',
        onSubmit: () => handleSubmit()
      }
    };
    if (id) {
      setConfirmModalProps(confirmModalPropsMap[confirmModalType]);
    } else {
      confirmModalPropsMap[confirmModalType].onSubmit();
    }
  };

  const handleLinkClick = () => {
    if (linkedItem || data?.externalBaseCode) {
      handleConfirmModal('unlinkItem');
      return;
    }
    setShowCatalogDrawer(true);
  };

  useEffect(() => {
    if (triggerLink) {
      handleLinkClick();
      setTriggerLink(false);
    }
  }, [triggerLink]);

  useEffect(() => {
    if (triggerSubmit) {
      if (isLibrary) {
        handleConfirmModal('editLibraryItem');
        return;
      }
      handleSubmit();
    }
  }, [triggerSubmit]);

  const EditableForm = useMemo(() => {
    return (
      <Form
        onValueChanged={val => {
          setTouched(!!val);
        }}
        isFormValid={setIsEditableFormValid}
        keepSchema
        validateOnRender
        resetValuesChanged={!touched}
        schema={itemSchema({ itemTypeEnum })}
        mapping={() =>
          itemMapping({
            data: {
              type: itemTypeEnum.composition,
              itemTypeEnum,
              isMobile: isMobile(),
              isCustomer,
              isEditing: data?.id,
              onlyView: isCustomer || readOnly,
              idCompany,
              linkedItem,
              isLibrary,
              externalBaseCode: data?.externalBaseCode,
              headerProps: {
                onEditItem:
                  linkedItem && !isCustomer && !readOnly
                    ? () => openNewTab(`/profissional/biblioteca/composicoes?id=${linkedItem?.id}`)
                    : null,
                showLinkInfo: !isLibrary
              }
            },
            checkedValue,
            setCheckedValue
          })
        }
        loading={loading}
        onSubmit={isEdit || id ? () => handleConfirmModal('editLibraryItem') : handleSubmit}
        data={{
          ...initialValues,
          autoCode: true,
          ...data,
          units: data?.item?.units || data?.units,
          class: data?.item?.class || data?.class,
          // eslint-disable-next-line prettier/prettier
          socialCharges: data?.priceWithSocialCharges ? 'Não desonerado' : 'Desonerado',
          type: itemTypeEnum.composition
        }}
        customButtonTitle={isEdit ? 'Salvar' : 'Criar'}
        style={{ marginBottom: '16px' }}
        displayButtons={displayButtons && !isMobile()}
        onTouchedChanged={!linkedItem ? _setFormData('labor') : undefined}
        saveWarningMessage="Existem alterações nos detalhes desta composição que precisam ser salvas."
      />
    );
  }, [touched, data, linkedItem, loading]);

  return (
    <>
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <ConfirmModal
            open={!!confirmModalProps}
            onClose={() => {
              setConfirmModalProps(null);
              setTriggerSubmit(false);
            }}
            alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
            {...(confirmModalProps || {})}
          />
          {showCatalogDrawer && (
            <CatalogDrawer
              open
              shouldConfirm
              cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
              fixedType={itemTypeEnum.composition}
              disableTypeFilter
              tabsToOpen={[0, 1]}
              newItemTypeRedirect={itemTypeEnum.composition}
              onClose={() => setShowCatalogDrawer(false)}
              onSubmit={val => {
                setShowCatalogDrawer(false);
                handleSaveLink(val);
              }}
            />
          )}
          <Box
            style={{
              marginRight: `${secondDrawer && !isMobile() && !idReference ? widthToComposition : 0}px`,
              position: 'relative',
              borderRadius: `${radius.radius1} ${radius.radius1} 0 0`,
              ...(noPadding && { padding: '0px' })
            }}
          >
            {EditableForm}
            {isEdit && !onlyLabor && <Line />}
            {(isEdit || id) && !onlyLabor && (
              <CompositionItems
                model={model}
                list={listItems}
                handleCreate={_handleCreateItems}
                handleLoadItems={handleLoad}
                setOpenCatalog={setSecondDrawer}
                setExtraDrawerStyle={setExtraDrawerStyle}
                isCustomer={isCustomer}
                idReference={idReference}
                renderColumns={renderColumns}
                readOnly={readOnly || !!linkedItem}
                isTemplate={isTemplate}
                idCompany={idCompany}
                externalTriggerProps={externalTriggerProps}
                data={data}
                hideProductService={model === 'item'}
                {...props}
              />
            )}
            {isEdit && !onlyLabor && <Line />}
          </Box>
          {!onlyLabor && (
            <Form
              onValueChanged={val => {
                setTouched(!!val);
              }}
              schema={itemSchema({ itemTypeEnum })}
              mapping={() =>
                budgetCompositionMapping({
                  data: {
                    type: itemTypeEnum.composition,
                    itemTypeEnum,
                    isMobile: isMobile(),
                    isCustomer,
                    isEditing: data?.id,
                    onlyView: isCustomer || readOnly,
                    idCompany,
                    isLibrary,
                    isTemplate,
                    idReference,
                    parentIsComposition
                  }
                })
              }
              loading={loading}
              displayButtons={false}
              data={{
                ...initialValues,
                ...(data || {}),
                quantity: formatNumberWithDynamicDecimals(data?.quantity)
              }}
              onTouchedChanged={_setFormData('original')}
            />
          )}
          {!isCustomer && (
            <CatalogDrawer
              open={secondDrawer}
              idParent={id}
              secondDrawer={secondDrawer}
              tabsToOpen={[0, 2]}
              onClose={() => {
                setSecondDrawer(false);
                setExtraDrawerStyle(0);
              }}
              onSubmit={_handleCreateItems}
              isComposition
            />
          )}
        </>
      )}
    </>
  );
};

Composition.propTypes = {
  id: PropTypes.number,
  onChangeData: PropTypes.func,
  model: PropTypes.string,
  noPadding: PropTypes.bool,
  setExtraDrawerStyle: PropTypes.func,
  displayButtons: PropTypes.bool,
  idReference: PropTypes.number,
  onClose: PropTypes.func,
  selectedTab: PropTypes.string,
  observation: PropTypes.string,
  files: PropTypes.instanceOf(Array),
  setSecondDrawer: PropTypes.func,
  secondDrawer: PropTypes.bool,
  groupedReference: PropTypes.string,
  includeFiles: PropTypes.bool,
  initialValues: PropTypes.instanceOf(Object),
  renderColumns: PropTypes.instanceOf(Object),
  readOnly: PropTypes.bool,
  isTemplate: PropTypes.bool,
  idCompany: PropTypes.number,
  options: PropTypes.instanceOf(Object),
  setReload: PropTypes.func,
  isLibrary: PropTypes.bool,
  externalTriggerProps: PropTypes.instanceOf(Object),
  onlyLabor: PropTypes.bool,
  parentIsComposition: PropTypes.bool
};

export default Composition;
