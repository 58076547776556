import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, Typography } from 'antd';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageInput from '../../../components/Input/ImageInput';
import formatCurrency from '../../helpers/formatCurrency';
import EditableInput from '../../../components/Input/EditableInput';
import formatNumber from '../../helpers/formatNumber';
import formatBdi from '../../helpers/formatBdi';
import StatusSelector from '../../../components/StatusSelector/StatusSelector';

import { Div, breakpoints, colors, fonts, spaces } from '../../../styles/style';
import ColumnWrapper from '../../../components/Table/ColumnWrapper';
import Button from '../../../components/Button/Button';
import { replaceDecimalNumber } from '../../helpers/helper';

StatusSelector.propTypes = {
  status: PropTypes.instanceOf(Object)
};

const { Text } = Typography;

const StyledMobileTextsDiv = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 5;
  flex-direction: column;
  padding: 4px 12px;

  span {
    color: ${colors.neutral500};
  }

  .title {
    flex: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.6;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-flow: wrap;

    @media (max-width: ${breakpoints.tablet}) {
      svg {
        position: absolute;
        right: ${props => (props.isChildren ? '16px' : '8px')};
        top: 0;
      }
    }
  }

  .supplier {
    color: ${colors.primary600};
  }
  .total {
    font-size: ${fonts.sizeSm};
  }
`;

const columns = ({
  expandedRows,
  refurbishItemType = {},
  isCustomer,
  handleChange = f => f,
  onRemoveClick = f => f,
  isMobile,
  readOnly,
  unitList,
  refetchLists = f => f,
  hasPermission,
  setShowUnitItem = f => f,
  forceCellUpdate,
  loading,
  idReference,
  columnsToShow,
  parentData
}) => {
  const _disabledColumn = isCustomer || readOnly;
  const allColumns = [
    {
      title: 'Tipo',
      dataIndex: 'image',
      key: 'image',
      className: 'image width-70',
      colSpan: 2,
      renderMobile: true,
      alwaysShow: true,
      render: (val, row) => {
        let _val = val;
        if (row.type === refurbishItemType.parent)
          _val = expandedRows?.current?.includes(row.id) || row.open ? 'folderOpen' : 'folder';

        return (
          <ImageInput
            key={`image${row.id}`}
            id={row.id}
            value={_val}
            images={row.images}
            onSubmit={({ image }) => {
              const spliceValue = row.images?.length ? row.images?.slice(1) : [];
              handleChange(row, 'images')([image, ...spliceValue]);
            }}
            disabled={_disabledColumn || row.isDisabled || isMobile || !!_val}
            onClick={row.onClick}
          />
        );
      }
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      className: 'width-110',
      render: (val, row) => {
        return (
          <EditableInput
            id="composition-item-code"
            key={`code${val}`}
            noWrap
            value={val}
            row={row}
            onChange={handleChange(row, 'code')}
            disabled={_disabledColumn || row.isDisabled}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: false
    },
    {
      title: 'Descrição',
      dataIndex: 'name',
      key: 'name',
      className: 'col-grow-2',
      alwaysShow: true,
      render: (val, row) => {
        const { onClick = f => f } = row;

        return (
          <Div gap={spaces.space1}>
            <EditableInput
              id="composition-item-name"
              key={`name${row.id}`}
              value={val}
              row={row}
              onChange={handleChange(row, 'name')}
              disabled={_disabledColumn || row.isDisabled}
              withEditButton
              onClick={onClick}
              rowId={row.id}
            />
            {row.item && !isCustomer && (
              <Tooltip title={row.item?.idCompany ? 'Vinculado com a biblioteca' : row.item?.source}>
                <FontAwesomeIcon className="linked-icon" icon={faBookmark} size="lg" color={colors.neutral500} />
              </Tooltip>
            )}
          </Div>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord?.name !== nextRecord?.name ||
        prevRecord?.link !== nextRecord?.link ||
        prevRecord?.idItem !== nextRecord?.idItem
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
      className: 'width-90',
      render: (val, row) => {
        let decimalCount;
        if (val) {
          decimalCount = replaceDecimalNumber(val);
        }
        return row.type === refurbishItemType.parent ? null : (
          <EditableInput
            id="composition-item-quantity"
            key={`qtd${row.id}`}
            noWrap
            value={formatCurrency(val, { decimalCount })}
            row={row}
            onChange={value => handleChange(row, 'quantity')(formatNumber(value))}
            disabled={_disabledColumn || row.isDisabled}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.quantity !== nextRecord.quantity
    },
    {
      title: 'Unidade',
      dataIndex: 'units',
      key: 'units',
      className: 'width-90',
      render: (val, row) => {
        return (
          <ColumnWrapper
            id="composition-item-unit"
            value={val}
            model="unit"
            onSelect={handleChange(row, 'idUnit')}
            loadList={unitList}
            disabled={_disabledColumn}
            refetch={refetchLists('unit')}
            setShowOpenDrawer={() => setShowUnitItem(true)}
            editOptions
            hasPermission={hasPermission}
            canCreate={hasPermission}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.idUnit !== nextRecord.idUnit || forceCellUpdate === 'unit'
    },
    {
      title: 'Custo unitário',
      dataIndex: 'price',
      key: 'price',
      className: 'width-90',
      render: (val, row) => {
        return row.type === refurbishItemType.parent ? null : (
          <EditableInput
            id="composition-item-price"
            key={`price${row.id}`}
            noWrap
            value={val ? formatCurrency(val, { currencySymbol: 'R$' }) : null}
            row={row}
            onChange={value => handleChange(row, 'price')(formatNumber(value))}
            disabled={_disabledColumn || row.isDisabled}
            rowId={row.id}
          />
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.price !== nextRecord.price
    },
    ...(idReference
      ? [
          {
            title: 'BDI',
            dataIndex: 'bdi',
            key: 'bdi',
            className: 'width-110',
            render: (val, row) => {
              const bdiFieldMap = {
                [refurbishItemType?.product]: val || parentData?.productBdi,
                [refurbishItemType?.labor]: val || parentData?.laborBdi,
                [refurbishItemType?.composition]: row?.calculatedBdi || val
              };
              const bdi = bdiFieldMap[row.type];

              return (
                <EditableInput
                  key={`bdi${row.id}`}
                  noWrap
                  value={bdi ? `${formatCurrency(bdi)}%` : null}
                  row={row}
                  onChange={value => handleChange(row, 'bdi')(formatBdi(value))}
                  disabled={isCustomer || row.isDisabled}
                  rowId={row.id}
                />
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.bdi !== nextRecord.bdi
          }
        ]
      : []),
    {
      title: 'Preço total',
      dataIndex: idReference ? 'totalBdi' : 'total',
      className: 'width-110',
      key: 'totalBdi',
      render: (val, row) => (
        <div id="composition-item-total-price" key={`totalBdi${row.id}`}>
          {formatCurrency(val, { currencySymbol: 'R$' })}
        </div>
      ),
      shouldCellUpdate: (prevRecord, nextRecord) =>
        prevRecord.totalBdi !== nextRecord.totalBdi || prevRecord.total !== nextRecord.total
    },
    {
      title: '',
      dataIndex: 'name',
      renderMobile: true,
      onlyMobile: true,
      render: (val, row = {}) => {
        const { longPressEvent = f => f } = row;
        return (
          <StyledMobileTextsDiv key={`content${row.id}`} isChildren={row.isChildren} {...(longPressEvent(row) || {})}>
            <div className="title">{row?.name}</div>
            <div className="content">
              <Text className="supplier">{row.supplier?.name}</Text>
              <Text className="total">
                <b>{formatCurrency(row.total, { currencySymbol: 'R$' })}</b>
              </Text>
              <Text>{row.quantity} Un</Text>
            </div>
            <Text>{row?.costCenter?.name}</Text>
          </StyledMobileTextsDiv>
        );
      },
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.status !== nextRecord.status
    },
    ...(!_disabledColumn
      ? [
          {
            title: 'Ações',
            key: 'action',
            className: 'width-50',
            renderMobile: true,
            alwaysShow: true,
            render: (_, row) => {
              return (
                <Tooltip title="Remover">
                  <Button
                    type="text"
                    style={{ color: colors.red500 }}
                    onClick={e => {
                      e.stopPropagation();
                      return onRemoveClick(row);
                    }}
                    loading={loading}
                    icon={<FontAwesomeIcon icon={faTrashAlt} size="lg" />}
                  />
                </Tooltip>
              );
            },
            shouldCellUpdate: (prevRecord, nextRecord) =>
              prevRecord.id !== nextRecord.id || forceCellUpdate === 'actions'
          }
        ]
      : [])
  ];

  const renderCollumns =
    columnsToShow || isCustomer
      ? allColumns.filter(c => (columnsToShow?.[c.key] || c.alwaysShow) && !c.onlyMobile)
      : allColumns.filter(c => !c.onlyMobile);

  return isMobile ? allColumns.filter(c => c.renderMobile && columnsToShow?.[c.key]) : renderCollumns;
};

const bdiValuesColumns = ({ isMobile, hideProductService }) => {
  const allbdiValuesColumns = [
    {
      title: '',
      dataIndex: 'rowName',
      className: 'width-120',
      key: 'rowName',
      render: (val, row) => <div key={`rowName${row.id}`}>{row.rowName}</div>,
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalProduct !== nextRecord.totalProduct
    },
    ...(!hideProductService
      ? [
          {
            title: 'Produtos',
            dataIndex: 'totalProduct',
            className: 'width-120',
            key: 'totalProduct',
            render: (val, row) => (
              <div key={`totalProduct${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalProduct !== nextRecord.totalProduct
          },
          {
            title: 'Serviços',
            dataIndex: 'totalLabor',
            className: 'width-120',
            key: 'totalLabor',
            render: (val, row) => (
              <div key={`totalLabor${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>
            ),
            shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalLabor !== nextRecord.totalLabor
          }
        ]
      : []),
    {
      title: 'Total',
      dataIndex: 'totalBdi',
      className: 'width-120 font-weight-600',
      key: 'totalBdi',
      render: (val, row) => <div key={`totalBdi${row.id}`}>{formatCurrency(val, { currencySymbol: 'R$' })}</div>,
      shouldCellUpdate: (prevRecord, nextRecord) => prevRecord.totalBdi !== nextRecord.totalBdi
    }
  ];
  const _allbdiValuesColumns = allbdiValuesColumns;

  return isMobile ? _allbdiValuesColumns.filter(c => c.renderMobile) : _allbdiValuesColumns.filter(c => !c.onlyMobile);
};

export { columns, bdiValuesColumns };
