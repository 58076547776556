import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faHelmetSafety,
  faMoneyBillTrendUp,
  faPeopleGroup,
  faScreenUsers,
  faSitemap,
  faUsers,
  faWallet
} from '@fortawesome/pro-solid-svg-icons';
import { AboutContent, AboutYouCardGroup, IconCard } from './Onboarding.styled';
import useViewport from '../../../_Hooks/useViewport';
import { Paragraph, Subtitle, Title } from '../../../components/Text/Text';
import { fonts } from '../../../styles/style';

const MainChallenges = ({ data, onChange }) => {
  const { mainChallengesList } = useSelector(state => state.setup.systemData);
  const { isMobile } = useViewport(window.innerWidth);

  const _isMobile = isMobile();

  const iconsMap = {
    users: faUsers,
    'screen-users': faScreenUsers,
    'helmet-safety': faHelmetSafety,
    sitemap: faSitemap,
    'money-bill-trend-up': faMoneyBillTrendUp,
    clock: faClock,
    wallet: faWallet,
    'people-group': faPeopleGroup
  };

  const [selectedCards, setSelectedCards] = useState({});

  const handleChange = val => {
    const newSelectedCards = { ...selectedCards, [val]: !selectedCards[val] };
    setSelectedCards(newSelectedCards);
    onChange({
      ...data,
      onboarding: {
        ...data.onboarding,
        mainChallenge: Object.entries(newSelectedCards).reduce((acc, [key, value]) => {
          if (value) {
            acc.push(Number(key));
          }

          return acc;
        }, [])
      }
    });
  };

  return (
    <AboutContent>
      <Title id="onboarding-page-title">Configurando a sua conta</Title>
      <div>
        <Subtitle weight={fonts.weight600}>Quais os principais desafios que podemos te ajudar a resolver?</Subtitle>
        <Subtitle> (selecione todos que aplicar)</Subtitle>
      </div>
      <AboutYouCardGroup>
        {mainChallengesList.map(challenge => (
          <IconCard
            key={challenge.value}
            onClick={() => handleChange(challenge.value)}
            $isSelected={selectedCards?.[challenge.value]}
          >
            <FontAwesomeIcon icon={iconsMap[challenge.icon]} size={_isMobile ? 'md' : 'xl'} />
            <Paragraph className="info-text">{challenge.label}</Paragraph>
          </IconCard>
        ))}
      </AboutYouCardGroup>
    </AboutContent>
  );
};

MainChallenges.propTypes = {
  data: PropTypes.instanceOf(Object),
  onChange: PropTypes.func
};

export default MainChallenges;
