import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useDrag, useDrop } from 'react-dnd';
import { faTrashAlt, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import Button from '../Button/Button';
import { colors, fonts, radius, spaces } from '../../styles/style';
import EditableInput from '../Input/EditableInput';
import ColorPickerDropDown from '../Dropdown/ColorPickerDropDown';
import { Subtitle } from '../Text/Text';

const { Text } = Typography;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${radius.radius1};
  padding: ${spaces.space0};
  margin-bottom: ${spaces.space1};
  background: #eef1f9;
  cursor: grab;
  ${props => props.$isOnboarding && `color: ${colors.primary500};`}
  ${props => props.$isOnboarding && `height: ${spaces.space6};`}
  ${props => props.$isOnboarding && `padding: ${spaces.space0} ${spaces.space1};`}

  .firstColumn {
    flex: 1;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: ${spaces.space1};
  }

  .otherColumns {
    display: flex;
    justify-content: center;
    padding-left: ${spaces.space1};
  }

  .icon {
    margin: ${spaces.space1};
  }

  ${props =>
    props.$isOver &&
    css`
      opacity: 0.5 !important;
    `}

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}
`;

const StepHeader = styled.div`
  margin: ${spaces.space2} 0;
  padding-bottom: ${spaces.space1};
`;

const Steps = ({ list, onSubmit, onChange, onDelete, isOnboarding, $listItemStyles }) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const [kanbanList, setKanbanList] = useState([]);
  const [otherList, setOtherList] = useState([]);
  const [fixedList, setFixedList] = useState([]);
  const [newItemId, setNewItemId] = useState(null);

  const isBO = user.userType === userType.operator.value;

  const moveRow = useCallback(
    (dragRow, dropRow, dragIndex, dropIndex) => {
      if (dragRow?.order === dropRow?.order) return;

      let beforeBoundary = 0;
      let afterBoundary = 0;

      if (dragIndex < dropIndex) {
        beforeBoundary = dropRow?.order;
        afterBoundary = list?.[dropIndex + 1]?.order || Number(dropRow?.order) + 1;
      } else {
        beforeBoundary = list?.[dropIndex - 1]?.order || 0;
        afterBoundary = dropRow?.order;
      }

      const order = (Number(beforeBoundary || 0) + Number(afterBoundary)) / 2;

      onChange(dragRow, { order, isActive: dropRow?.isActive });
    },
    [list]
  );

  const moveRowFixed = useCallback(
    dragRow => {
      const nextItemOrder = otherList?.[0]?.order || 1;

      const order = Number(nextItemOrder) / 2;

      onChange(dragRow, { order, isActive: false });
    },
    [otherList]
  );

  useEffect(() => {
    const activeSteps = [];
    const inactiveSteps = [];
    const fixedSteps = [];
    list.forEach(l => {
      if (l.isActive) {
        activeSteps.push(l);
        return;
      }
      if (l.fixed) {
        fixedSteps.push(l);
        return;
      }
      inactiveSteps.push(l);
    });
    setKanbanList(activeSteps);
    setOtherList(inactiveSteps);
    setFixedList(fixedSteps);
  }, [list]);

  const RenderList = ({ item, index, fixed }) => {
    const ref = useRef();
    const { id, name, color, order } = item || {};

    const [{ isDragging }, dragRef] = useDrag({
      item: { type: 'row', dragItem: item, dragIndex: index },
      collect: monitor => ({
        isDragging: monitor.isDragging()
      })
    });

    const [{ isOver }, dropRef] = useDrop({
      accept: 'row',
      collect: monitor => {
        return { isOver: monitor.isOver() };
      },
      drop(_item) {
        const { dragItem, dragIndex } = _item;
        if (fixed) {
          moveRowFixed(dragItem);
          return;
        }
        moveRow(dragItem, item, dragIndex, index);
      }
    });

    const opacity = isDragging ? 0 : 1;
    dragRef(dropRef(ref));

    return (
      <ListItem ref={ref} style={{ opacity }} $isOver={isOver} $isOnboarding={isOnboarding} {...$listItemStyles}>
        {!fixed && (
          <div className="icon">
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </div>
        )}
        <div className="firstColumn">
          <EditableInput
            id="step-name-input"
            disabled={fixed}
            key={`name${id}`}
            initEdit={newItemId === id}
            value={name}
            onChange={val => {
              onChange(item, { name: val });
              setNewItemId(null);
            }}
            style={{ width: '100%' }}
          />
          {fixed && (
            <div className="icon">
              <Tooltip title="Status padrão da Vobi não podem ser alterados">
                <FontAwesomeIcon icon={faInfoCircle} color={colors.primary600} />
              </Tooltip>
            </div>
          )}
        </div>
        {isBO && (
          <div className="otherColumns">
            <EditableInput
              key={`order${id}`}
              value={order}
              initEdit={newItemId === id}
              onChange={val => {
                onChange(item, { order: val });
                setNewItemId(null);
              }}
              style={{ width: '100%' }}
            />
          </div>
        )}
        <div className="otherColumns">
          <ColorPickerDropDown color={color} onSelect={val => onChange(item, { color: val })} disabled={fixed} />
        </div>
        {!fixed && (
          <div className="otherColumns">
            <Button id="step-delete" type="text" onClick={() => onDelete(item)} style={{ height: '100%', padding: 0 }}>
              <FontAwesomeIcon color={colors.red500} icon={faTrashAlt} />
            </Button>
          </div>
        )}
      </ListItem>
    );
  };

  RenderList.propTypes = {
    item: PropTypes.instanceOf(Object),
    index: PropTypes.number,
    fixed: PropTypes.bool
  };

  const AddStatusButton = ({ addText, isActive = false, id }) => {
    return (
      <Button
        id={id}
        type="text"
        style={{
          color: colors.primary500,
          ...(isOnboarding ? { fontSize: fonts.sizeMd, marginTop: spaces.space2 } : {})
        }}
        onClick={() =>
          onSubmit({ values: { name: '', isActive, isKanban: isOnboarding } }).then(resp => {
            if (resp?.error) {
              toast.error('Erro ao criar status.');
              return;
            }
            setNewItemId(resp.id);
          })
        }
      >
        + {addText}
      </Button>
    );
  };

  AddStatusButton.propTypes = {
    addText: PropTypes.string,
    isActive: PropTypes.bool,
    id: PropTypes.string
  };

  return (
    <>
      {!isOnboarding && (
        <div style={{ marginBottom: spaces.space4 }}>
          <span>Configure aqui os status dos seus projetos</span>
        </div>
      )}
      <StepHeader>
        {!isOnboarding && (
          <div className="title">
            <b>Status ativos</b>
          </div>
        )}
        {isOnboarding && <Subtitle>Ajuste a ordem e altere os textos do jeito que precisar.</Subtitle>}
      </StepHeader>
      <ul id="kanban-list-active">
        {kanbanList?.map((kanbanItem, index) => (
          <RenderList item={kanbanItem} index={index} key={kanbanItem?.id} />
        ))}
      </ul>
      <div>
        <AddStatusButton addText="Novo status ativo" isActive id="add-step" />
      </div>
      {!isOnboarding && (
        <>
          <br />
          <StepHeader>
            <Text>
              <div className="title">
                <b>Status fechados</b>
              </div>
              <div className="info-text">Para os projetos que não serão exibidos nas visualizações por padrão.</div>
            </Text>
          </StepHeader>
          <ul id="kanban-list-inactive">
            {fixedList?.map(fixedItem => (
              <RenderList item={fixedItem} key={fixedItem?.id} fixed />
            ))}
          </ul>
          <ul id="other-kanban-list-inactive">
            {otherList?.map((otherItem, index) => (
              <RenderList item={otherItem} index={index + (kanbanList?.length || 0)} key={otherItem?.id} />
            ))}
          </ul>
          <div>
            <AddStatusButton addText="Novo status fechado" id="add-step-inactive" />
          </div>
        </>
      )}
    </>
  );
};

Steps.propTypes = {
  list: PropTypes.instanceOf(Array),
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  isOnboarding: PropTypes.bool,
  $listItemStyles: PropTypes.instanceOf(Object)
};

export default Steps;
