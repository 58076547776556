import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../Button/Button';
import Drawer from './Drawer';
import useCRUD from '../../_Hooks/useCRUD';
import useViewport from '../../_Hooks/useViewport';
import { filterForm } from '../../lib/mapping/Form/filterForm';
import { handleFilter as handleFilterHelper } from '../../lib/helpers/filterFunctions';
import { selectQuoteSuppliersColumns } from '../../lib/mapping/Form/quoteSchema';
import SearchInput from '../Filters/SearchInput';
import FiltersByModel from '../Filters/FiltersByModel';
import { Div, colors, spaces } from '../../styles/style';
import FilterTags from '../Tag/FilterTags';
import TableOrListV2 from '../List/TableOrListV2';
import { Paragraph } from '../Text/Text';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import EditOrCreateCustomerOrSupplier from './EditOrCreateCustomerOrSupplier';
import TableNoContent from '../NoContent/TableNoContent';
import PurchaseOverviewFooter from '../MobileFooter/PurchaseOverviewFooter';

const AddQuoteSuppliersDrawer = ({
  onClose,
  idQuote,
  createOrUpdateQuote,
  afterAddQuoteSupplier,
  quoteSupplierList
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { purchaseQuoteStatus, quoteSupplierStatus } = useSelector(state => state.setup.enums);
  const { plans, permissions } = useSelector(state => state.setup);
  const { user } = useSelector(state => state.authReducer);

  const history = useHistory();
  const { search } = useLocation();

  const [isCreatingQuoteSupplier, setIsCreatingQuoteSupplier] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [appliedFilters, setAppliedFilters] = useState({});
  const [filter, setFilter] = useState({});

  const [order, setOrder] = useState(['name']);
  const [selectedQuoteSuppliersMap, setSelectedQuoteSuppliersMap] = useState({});
  const [showCreateSupplierDrawer, setShowCreateSupplierDrawer] = useState(false);
  const [suppliersOnListMap, setSuppliersOnListMap] = useState(null);

  const _isMobile = isMobile();

  const disableSaveAndSaveButton = !Object.keys(selectedQuoteSuppliersMap)?.length;

  const baseUrl = '/profissional/gestao-de-compras/cotacoes';

  const defaultOptions = { onlyMine: true, where: { isActive: true }, include: ['costCenter'] };

  const { list, handleGet, loading } = useCRUD({
    model: 'supplier',
    options: { ...defaultOptions, order },
    immediatelyLoadData: false
  });

  const { handleCreate } = useCRUD({
    model: 'quoteSuppliers/bulk',
    immediatelyLoadData: false
  });

  const { handleUpdate: handleUpdateQuote } = useCRUD({
    model: 'quote',
    immediatelyLoadData: false
  });

  const { handleCreate: createQuoteSuppliers } = useCRUD({
    model: 'quoteSuppliers',
    immediatelyLoadData: false
  });

  const onSelect = idSupplier => {
    if (isCreatingQuoteSupplier) return;
    setIsCreatingQuoteSupplier(true);
    createQuoteSuppliers({
      values: {
        idSupplier,
        idQuote,
        subTotal: 0
      },
      displayToast: true,
      refresh: false,
      noLoading: true
    }).then(() => {
      setIsCreatingQuoteSupplier(false);

      afterAddQuoteSupplier();
    });
  };

  const handleFilter = (selectedFilters = {}) =>
    handleFilterHelper({
      selectedFilters,
      setFilter
    });

  const SearchName = (
    <SearchInput
      id="search-input"
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      onFilter={handleFilter}
      textSearchKey="name"
      placeholder="Pesquisar por nome"
      width={_isMobile ? '100%' : '232px'}
    />
  );

  const Filters = (
    <FiltersByModel
      onFilter={handleFilter}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      object={filterForm.selectQuoteSuppliers}
      onlyFilterIconMobile
      fromMapping
    />
  );

  const AddSupplier = hasPermission(user, ['supplier'], plans, permissions) && (
    <Button type="primary" onClick={() => setShowCreateSupplierDrawer(true)}>
      <FontAwesomeIcon size="lg" icon={faPlus} color={colors.white} /> Novo fornecedor
    </Button>
  );

  const handleSorting = ({ key, order: _order }) => setOrder([[key, _order]]);

  const handleLoad = () => {
    handleGet({ refetchOptions: { ...defaultOptions, where: { ...defaultOptions.where, ...filter }, order } });
  };

  useEffect(() => {
    handleLoad();
  }, [filter, order]);

  useEffect(() => {
    if (!quoteSupplierList?.length) return;

    const newsuppliersOnListMap = {};

    quoteSupplierList?.forEach(quoteSupplier => {
      newsuppliersOnListMap[quoteSupplier?.supplier?.id] = true;
    });
    setSuppliersOnListMap(newsuppliersOnListMap);
  }, [quoteSupplierList]);

  const _columns = selectQuoteSuppliersColumns({
    isMobile: _isMobile,
    selectedQuoteSuppliersMap: suppliersOnListMap || selectedQuoteSuppliersMap,
    onSelect: afterAddQuoteSupplier
      ? (selectedSupplier, selected) => !selected && onSelect(selectedSupplier?.id)
      : (selectedSupplier, selected) =>
          setSelectedQuoteSuppliersMap(prev => {
            const newObj = { ...prev };
            if (selected) {
              delete newObj[selectedSupplier?.id];
            } else {
              newObj[selectedSupplier?.id] = selectedSupplier;
            }
            return newObj;
          })
  });

  const handleSubmit = () => {
    setIsSubmitting(true);
    createOrUpdateQuote().then(quote => {
      if (!quote) {
        setIsSubmitting(false);
        return null;
      }
      return handleCreate({
        refresh: false,
        values: {
          list: Object.keys(selectedQuoteSuppliersMap).map(qsId => ({
            idQuote: quote?.id,
            idSupplier: qsId,
            status: quoteSupplierStatus.pending.id
          }))
        }
      }).then(quoteSuppliers => {
        if (quoteSuppliers?.error) {
          setIsSubmitting(false);
          return null;
        }
        return handleUpdateQuote({
          refresh: false,
          id: quote?.id,
          values: { idQuoteStatus: purchaseQuoteStatus.open }
        }).then(resp => {
          setIsSubmitting(false);
          if (!resp?.error)
            history.push(
              `${baseUrl}/visualizar/${quote?.id}${search?.length ? `${search}&` : '?'}pedido=${encodeURIComponent(
                quoteSuppliers?.map(qs => qs?.id)
              )}`
            );
        });
      });
    });
  };

  return (
    <Drawer
      footer={
        <Div justify="space-between" $fullWidth>
          <Button onClick={onClose} text>
            Fechar
          </Button>
          {!afterAddQuoteSupplier && (
            <Button
              id="next-step-select-quote-suppliers"
              type="primary"
              disabled={disableSaveAndSaveButton}
              loading={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
            >
              Salvar e enviar
            </Button>
          )}
        </Div>
      }
      title="Adicionar fornecedores"
      open
      width="860px"
      padding={`${spaces.space1} ${spaces.space2}`}
      $footerPadding={`${spaces.space1} ${spaces.space2}`}
      onClose={() => onClose(false)}
    >
      {showCreateSupplierDrawer && (
        <EditOrCreateCustomerOrSupplier
          model="supplier"
          onClose={() => {
            setShowCreateSupplierDrawer(false);
            handleLoad();
          }}
        />
      )}
      <Div
        direction="column"
        align="baseline"
        gap={_isMobile ? undefined : spaces.space2}
        margin={`${spaces.space1} 0 0 0`}
        $fullWidth
      >
        <Div>
          <Paragraph>Busque pelos fornecedores que deseja solicitar pedido de cotação</Paragraph>
        </Div>
        <Div direction="column" align="left" id="filter-container" gap={spaces.space1} $fullWidth>
          {_isMobile ? (
            <Div
              direction="column"
              id="filters-input-container"
              gap={spaces.space1}
              margin={`${spaces.space2} 0 ${spaces.space1}`}
              $fullWidth
            >
              {SearchName}
              <Div justify="space-between" $fullWidth>
                {Filters}
                {AddSupplier}
              </Div>
            </Div>
          ) : (
            <Div justify="space-between" $fullWidth>
              <Div align="baseline" gap={spaces.space1} id="filters-input-container">
                {SearchName}
                {Filters}
              </Div>
              {AddSupplier}
            </Div>
          )}
          <FilterTags appliedFilters={appliedFilters} setAppliedFilters={setAppliedFilters} onFilter={handleFilter} />
        </Div>
        <TableOrListV2
          id="SelectQuoteSuppliersTable"
          columns={_columns}
          list={list}
          readOnlyMobile
          scroll={{ y: 'calc(100vh - 264px)', x: '100%' }}
          refreshColumns
          initialExpandedRowKeys={[]}
          $childRowColor={colors.neutral50}
          rowSelection={false}
          onSort={handleSorting}
          withCommonRow
          isLoading={loading}
          customEmptyState={() => <TableNoContent description="Nenhum item encontrado." renderButton={false} />}
          mobileItemFooter={PurchaseOverviewFooter}
        />
      </Div>
    </Drawer>
  );
};

AddQuoteSuppliersDrawer.propTypes = {
  onClose: PropTypes.func,
  idQuote: PropTypes.string,
  createOrUpdateQuote: PropTypes.func,
  quoteSupplierList: PropTypes.instanceOf(Array),
  afterAddQuoteSupplier: PropTypes.func
};

export default AddQuoteSuppliersDrawer;
