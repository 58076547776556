import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useCRUD from '../../_Hooks/useCRUD';
import { TabContent } from '../Modal/EditItemModal.styles';
import Filters from '../Filters/Filters';
import CatalogCard from '../Card/CatalogCard';
import { ITEMS_PAGE, redirectType, images } from '../../lib/mapping/Tabs/catalogTab';
import { filterForm } from '../../lib/mapping/Form/filterForm';
import Button from '../Button/Button';
import { isNumber, RangePrice } from '../../lib/helpers/helper';
import { spaces } from '../../styles/style';
import CenteredLoader from '../Loader/CenteredLoader';

const MyLibraryTab = ({
  cardProps,
  isComposition,
  onSelect,
  keyword,
  newItemTypeRedirect,
  disableTypeFilter,
  tabIndex,
  offset,
  setOffset,
  fixedType,
  disableCardButton
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { refurbishItemType } = useSelector(state => state.setup.enums);
  const { refurbishItemTypes } = useSelector(state => state.setup.systemData);
  const [queryString, setQueryString] = useState();
  const [displayList, setDisplayList] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [costCenterFilters, setCostCenterFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);

  const { loading, totalItems, handleGet } = useCRUD({
    model: 'item',
    immediatelyLoadData: false
  });

  const { list: listCostCenter } = useCRUD({
    model: 'costCenter',
    options: {
      where: {
        isActive: true
      },
      onlyMine: true,
      order: ['name']
    },
    immediatelyLoadData: true
  });

  const NoContentInfo = images[!hasSearched ? 'initialState' : 'notFound'];

  const handleMyProductsFilter = _filters => {
    const { Categoria: costCenter = [], Tipo: type = [], Preço: price } = _filters;

    const { min, max } = price || {};
    const isRange = isNumber(min) && isNumber(max);

    const _costCenter = costCenter.map(({ value }) => value);
    const _type = type.map(({ value }) => value);

    const newQueryString = {
      ...queryString,
      where: { price: RangePrice(isRange, min, max), idCostCenter: _costCenter, type: _type },
      offset: 1
    };

    const newCostCenterFilter = costCenterFilters.map(item => ({ ...item, checked: _costCenter.includes(item.id) }));
    const newTypeFilter = typeFilters.map(item => ({ ...item, checked: _type.includes(item.value) }));
    setCostCenterFilters(newCostCenterFilter);
    setTypeFilters(newTypeFilter);
    setQueryString(newQueryString);
    setOffset(1);
  };

  const handleSearchSuggestion = value => {
    setQueryString({ ...queryString, offset: 1, keyword: value, where: {} });
  };

  useEffect(() => {
    if (listCostCenter && listCostCenter.length) {
      setCostCenterFilters(listCostCenter.map(({ name, id }) => ({ checked: false, label: name, value: id })));
    }
  }, [listCostCenter]);

  useEffect(() => {
    if (!queryString) return;

    const { keyword: _keyword, where } = queryString || {};
    const nameOrCode = _keyword ? [{ name: { like: `%${_keyword}%` } }, { code: { like: `%${_keyword}%` } }] : {};
    const getComposition = refurbishItemType.notParent;
    const type = where?.type || fixedType || getComposition;

    handleGet({
      refetchOptions: {
        where: { ...where, or: nameOrCode, type },
        offset,
        limit: ITEMS_PAGE,
        include: ['supplier', 'children', 'units']
      },
      generateLoading: offset <= 1
    }).then(resp => {
      if (offset === 1) {
        setDisplayList(resp);
      } else {
        setDisplayList(prev => prev.concat(resp));
      }
    });
  }, [queryString]);

  useEffect(() => {
    const hasItemsToLoad = totalItems > displayList.length && !loading;
    if (offset > 1 && hasItemsToLoad) setQueryString({ ...queryString, offset });
  }, [offset]);

  useEffect(() => {
    if (tabIndex === 0) {
      setOffset(1);
      setHasSearched(!!keyword);
      setQueryString({ ...queryString, offset: 1, keyword, where: {} });
    }
  }, [keyword, tabIndex]);

  useEffect(() => {
    if (refurbishItemTypes && refurbishItemTypes?.length) {
      setTypeFilters(
        refurbishItemTypes
          ?.filter(p =>
            isComposition
              ? !p?.hideFilter && p?.notParent
              : (!fixedType || fixedType === p?.value || (Array.isArray(fixedType) && fixedType?.includes(p?.value))) &&
                !p.hideFilter
          )
          .map(({ label, value }) => ({ checked: false, label, value }))
      );
    }
  }, [refurbishItemTypes]);

  return (
    <TabContent>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Filters
          key="myproducts"
          position="bottom-center"
          filters={filterForm.itemsToComposition(costCenterFilters, typeFilters, disableTypeFilter)}
          onFilter={handleMyProductsFilter}
          displaySorting={false}
        />
        <Button
          type="primary"
          text
          onClick={() =>
            window.open(
              `/${user.type}/biblioteca/${
                newItemTypeRedirect ? `${redirectType[newItemTypeRedirect]}?create=true` : 'produtos'
              }`,
              '_blank'
            )
          }
        >
          Novo item
        </Button>
      </div>

      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          {!totalItems || !displayList.length ? (
            <NoContentInfo keyword={keyword} onClick={handleSearchSuggestion} loading={loading} />
          ) : (
            <div style={{ paddingTop: spaces.space1 }} id="my-library">
              {displayList &&
                Array.isArray(displayList) &&
                displayList?.map(_data => (
                  <React.Fragment key={_data?.id}>
                    <CatalogCard
                      {...cardProps}
                      renderInComposition={isComposition}
                      id={_data?.id}
                      data={_data}
                      onSelect={onSelect}
                      redirectUrl={`/${user.type}/biblioteca/${redirectType[_data.type]}/editar/${_data.id}`}
                      className="catalogItem"
                      disableCardButton={disableCardButton}
                    />
                  </React.Fragment>
                ))}
            </div>
          )}
        </>
      )}
    </TabContent>
  );
};

MyLibraryTab.propTypes = {
  cardProps: PropTypes.instanceOf(Object),
  onSelect: PropTypes.func,
  isComposition: PropTypes.bool,
  keyword: PropTypes.string,
  disableTypeFilter: PropTypes.bool,
  newItemTypeRedirect: PropTypes.number,
  tabIndex: PropTypes.number,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
  fixedType: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  disableCardButton: PropTypes.bool
};

export default MyLibraryTab;
