import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as AntButton, Tooltip } from 'antd';
import { spaces, colors, fonts } from '../../styles/style';

const StyledButton = styled(AntButton)`
  display: flex;
  align-self: ${props => props.align || 'center'};
  align-items: ${props => props.$alignItems || 'center'};
  padding: ${props => (props.$padding || props.$padding === 0 ? props.$padding : `${spaces.space1}`)};
  white-space: nowrap;
  width: ${props => (props?.width ? props.width : 'min-content')};
  ${props => props.$minWidth && `min-width: ${props.$minWidth};`};
  justify-content: ${props => (props.$justifyContent ? props.$justifyContent : 'center')};
  background-position: center;
  font-weight: ${fonts.weight500};
  color: ${props => (props.color ? colors[props.color] : colors.neutral600)};
  cursor: pointer;
  font-size: ${props => (props.$fontSize ? props.$fontSize : fonts.sizeMd)};
  transition: transform 0.5s;
  ${props => props?.margin && `margin: ${props.margin}`};
  ${props => props?.underline && `text-decoration-line: underline`};

  svg {
    margin: 4px;
  }

  ${props =>
    props.$fullWidth &&
    css`
      width: 100%;
      justify-content: center;
    `}

  &.ant-btn-sm {
    font-size: ${fonts.sizeXs};
  }

  &.ant-btn-round {
    border-radius: ${props => props.$borderRadius || 24}px;
    ${props => props.$roundPadding && `padding: ${props.$roundPadding};`}
    ${props => props.$roundMaxHeight && `max-height: ${props.$roundMaxHeight};`}
  }

  &.ant-btn-circle {
    ${props => props.$roundMaxHeight && `min-width: ${props.$roundMaxHeight};`}
    ${props => props.$roundMaxHeight && `max-width: ${props.$roundMaxHeight};`}
    ${props => props.$roundMaxHeight && `max-height: ${props.$roundMaxHeight};`}
  }

  &:focus,
  &:hover {
    border-color: ${colors.neutral700};
    color: ${colors.neutral700};
  }

  &.ant-btn-default {
    border: solid 1px currentColor;

    &:active {
      color: currentColor;
      border: solid 1px currentColor;
      transform: scale(0.92);
    }
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &.ant-btn-primary {
        color: ${props => (props.$text ? colors.primary600 : colors.white)};
        border: solid 1px ${props => colors[props.$primaryBorderColor] || colors.primary600};

        &:focus {
          background-color: ${colors.primary600};
        }
        &:hover {
          color: ${props => (props.$text ? colors.primary700 : colors.white)};
          border-color: ${colors.primary700};
          background-color: ${colors.primary700};
        }

        &.ant-btn-background-ghost {
          color: ${colors.primary600};
          background-color: transparent;

          &:hover {
            border-color: ${colors.primary600};
            background-color: ${colors.primary600};
            color: ${props => (props.$text ? colors.primary700 : colors.white)};
          }
        }
      }
    `}

  ${({ pressed, type }) =>
    pressed &&
    css`
      background-color: ${type === 'default' ? colors.neutral800 : colors[`${type}700`]} !important;
      border-color: ${type === 'default' ? colors.neutral800 : colors[`${type}700`]} !important;
      color: white !important;

      ${type === 'iconNeutral' &&
        css`
          background-color: ${colors.neutral75} !important;
          border: 1px solid ${colors.neutral100} !important;
          color: ${colors.neutral600} !important;
        `}

      &.ant-btn-background-ghost {
        background-color: ${type === 'default' ? colors.neutral800 : colors[`${type}700`]} !important;
        border-color: ${type === 'default' ? colors.neutral800 : colors[`${type}700`]} !important;
      }
    `}

  &.ant-btn-success {
    color: ${props => (props.$text ? colors.green300 : colors.white)};
    background: ${colors.green300};
    border: solid 1px ${colors.green300};

    &:focus {
      background: ${colors.green300};
    }
    &:hover {
      color: ${props => (props.$text ? colors.green400 : colors.white)};
      border-color: ${colors.green400};
      background-color: ${colors.green400};
    }

    &.ant-btn-background-ghost {
      color: ${colors.green300};
      background: transparent;

      &:hover {
        border-color: ${colors.green400};
        background-color: ${colors.green400};
        color: ${props => (props.$text ? colors.green400 : colors.white)};
      }
    }
  }

  &.ant-btn-warning {
    color: ${props => (props.$text ? colors.yellow400 : colors.white)};
    background: ${colors.yellow400};
    border: solid 1px ${colors.yellow400};

    &:focus {
      background: ${colors.yellow400};
    }
    &:hover {
      color: ${props => (props.$text ? colors.yellow500 : colors.white)};
      border-color: ${colors.yellow500};
      background-color: ${colors.yellow500};
    }

    &.ant-btn-background-ghost {
      color: ${colors.yellow400};
      background: transparent;

      &:hover {
        border-color: ${colors.yellow500};
        background-color: ${colors.yellow500};
        color: ${props => (props.$text ? colors.yellow500 : colors.white)};
      }
    }
  }

  &.ant-btn-secondary {
    color: ${props => (props.$text ? colors.teal500 : colors.white)};
    background: ${colors.teal500};
    border: solid 1px ${colors.teal500};

    &:focus {
      background: ${colors.teal500};
    }
    &:hover {
      color: ${props => (props.$text ? colors.teal500 : colors.white)};
      border-color: ${colors.teal500};
      background-color: ${colors.teal500};
    }

    &.ant-btn-background-ghost {
      color: ${colors.teal500};
      background: transparent;

      &:hover {
        border-color: ${colors.teal500};
        background-color: ${colors.teal500};
        color: ${props => (props.$text ? colors.teal500 : colors.white)};
      }
    }
  }

  &.ant-btn-danger {
    color: ${props => (props.$text ? colors.red500 : colors.white)};
    background: ${colors.red500};
    border: solid 1px ${colors.red500};

    &:focus {
      background: ${colors.red500};
    }
    &:hover {
      color: ${props => (props.$text ? colors.red600 : colors.white)};
      border-color: ${colors.red600};
      background-color: ${colors.red600};
    }

    &.ant-btn-background-ghost {
      color: ${colors.red500};
      background: transparent;

      &:hover {
        border-color: ${colors.red600};
        background-color: ${colors.red600};
        color: ${props => (props.$text ? colors.red600 : colors.white)};
      }
    }
  }

  &.ant-btn-iconNeutral {
    background: transparent;
    box-shadow: none;
    border: none;
    color: ${colors.neutral500};

    &:hover {
      background-color: ${colors.neutral75};
      color: ${colors.neutral600};
    }

    &:active, &:focus {
      background-color: ${colors.neutral75};
      border: 1px solid ${colors.neutral100} !important;
    }
  };

  &.ant-btn-outline {
    background: ${colors.white};
    border: solid 1px ${colors.neutral100};

    &:hover {
      background: ${colors.neutral50};
      color: ${colors.primary600};
    }
    &:active{
      background-color: ${colors.neutral100};
    }

    ${props =>
      props.selected &&
      css`
        background-color: ${colors.primary50};
        border-color: ${colors.primary50};
        color: ${colors.primary600};

        &:focus,
        &:hover {
          background-color: ${colors.primary50};
          border-color: ${colors.primary50};
          color: ${colors.primary600};
        }
      `}

  }

  &.ant-btn-white {
    background: ${colors.white};
    border: solid 1px ${colors.white};

    &.ant-btn-background-ghost {
      background: transparent;
    }

    &:focus,
    &:hover {
      border-color: ${colors.white};
      color: ${colors.white};
    }
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &.ant-btn-light {
        color: ${colors.primary500};
        background: ${colors.primary50};
        border: none;

        &:active,
        &:focus {
          background-color: ${colors.primary50};
        }
        &:hover {
          background: ${colors.primary100};
        }

        &.ant-btn-background-ghost {
          color: ${colors.primary600};
          border: solid 1px ${colors.primary50};
          background: transparent;

          &:hover {
            border-color: ${colors.primary100};
          }
        }
      }
    `}

  &.ant-btn-text,
  &.ant-btn-link {
    font-weight: ${fonts.weight500};
    border: none;
    background: none;
    padding: ${spaces.space1} 0;

    &:after {
      animation: none !important;
    }

    &:active {
      border: none;
    }

    &:hover {
      border-color: currentColor;
      background: none;
    }

    svg {
      margin-right: ${spaces.space1};
    }
  }



  ${props =>
    props.$text &&
    css`
      border: none !important;
      background: none !important;
      box-shadow: none !important;
      font-weight: ${fonts.weight500};

      &:after {
        animation: none !important;
      }

      &:active {
        border: none !important;
      }
    `}
`;

const Button = ({
  id,
  loading,
  children,
  fullWidth,
  padding,
  borderRadius,
  roundPadding,
  roundMaxHeight,
  primaryBorderColor = 'primary600',
  text,
  minWidth,
  tooltipText = '',
  ...props
}) => {
  const buttonContent = (
    <StyledButton
      id={id}
      loading={loading}
      $fullWidth={fullWidth}
      $minWidth={minWidth}
      $padding={padding}
      $borderRadius={borderRadius}
      $roundPadding={roundPadding}
      $roundMaxHeight={roundMaxHeight}
      $primaryBorderColor={primaryBorderColor}
      $text={text}
      {...props}
    >
      {children}
    </StyledButton>
  );

  return tooltipText ? <Tooltip title={tooltipText}>{buttonContent}</Tooltip> : buttonContent;
};

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  shape: PropTypes.string,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.number,
  roundPadding: PropTypes.string,
  roundMaxHeight: PropTypes.string,
  primaryBorderColor: PropTypes.string,
  text: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tooltipText: PropTypes.string
};

export default Button;
