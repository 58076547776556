import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Div, breakpoints, colors, fonts, radius, spaces } from '../../styles/style';
import SelectDropdown from '../Dropdown/SelectDropdown';
import Avatar from '../AvatarEditor/Avatar';
import { getInitials } from '../../lib/helpers/helper';
import Label from '../Label/Label';

const SelectContainer = styled.div`
  border-radius: ${radius.radius1};
  transition: background-color 0.3s ease, border-color 0.3s ease;
  gap: ${spaces.space1};
  height: ${spaces.space4};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spaces.space0} 0 ${spaces.space1};

  .remove-icon {
    font-size: ${fonts.sizeMd};
    color: transparent;
    transition: color 0.3s ease;
  }

  &:hover {
    cursor: pointer;
    background-color: ${colors.neutral75};
    font-size: ${fonts.sizeMd};

    .remove-icon {
      color: ${colors.neutral600};

      &:hover {
        color: ${colors.neutral700};
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 0;

    &:hover {
      background-color: ${colors.white};
    }
  }
`;

const AvatarSelect = ({ user, disabled, id, onChange, open, cleanValue = null }) => {
  const [isEdit, setEdit] = useState(open);

  const handleClick = () => {
    if (!disabled) setEdit(true);
  };

  const handleSelect = (selectedId, isNewRecord, selectedUser) => {
    onChange(selectedId, isNewRecord, selectedUser);
    setEdit(false);
  };

  const handleRemove = e => {
    e.stopPropagation();
    onChange(cleanValue);
  };

  if (disabled) {
    return user ? (
      <Avatar id={`${id}-${user?.name}`} size="small" src={user?.avatarFullpath}>
        <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
          {getInitials(user?.name)}
        </Label>
      </Avatar>
    ) : (
      <Div gap={spaces.space1}>
        <Avatar id={`${id}-${user?.name}`} size="small" src={user?.avatarFullpath} dashed>
          <FontAwesomeIcon icon={faPlus} color={colors.neutral400} style={{ verticalAlign: '-1px' }} />
        </Avatar>
        <Label marginBottom="0px" color={colors.neutral400}>
          Selecione
        </Label>
      </Div>
    );
  }

  return isEdit ? (
    <SelectDropdown
      model="user"
      options={{ where: { isActive: true }, order: [['name']] }}
      onSelect={handleSelect}
      data={user}
      isOpen
      onClose={() => setEdit(false)}
      className="form-component"
      cleanValue={cleanValue}
      canCreate={false}
      buttonAlign="left"
      IconSelector={({ item }) => (
        <Avatar size="small" ghost src={item.avatarFullpath}>
          <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
            {getInitials(item?.name)}
          </Label>
        </Avatar>
      )}
    >
      {user && (
        <Avatar id={`${id}-${user?.name}`} size="small" src={user?.avatarFullpath}>
          <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
            {getInitials(user?.name)}
          </Label>
        </Avatar>
      )}
    </SelectDropdown>
  ) : (
    <SelectContainer role="presentation" onClick={handleClick} $showBorder={!user} disabled={disabled}>
      <Div gap={spaces.space1}>
        {user ? (
          <Avatar id={`${id}-${user?.name}`} size="small" src={user?.avatarFullpath}>
            <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
              {getInitials(user?.name)}
            </Label>
          </Avatar>
        ) : (
          <>
            <Avatar id={`${id}-${user?.name}`} size="small" src={user?.avatarFullpath} dashed>
              <FontAwesomeIcon icon={faPlus} color={colors.neutral400} style={{ verticalAlign: '-1px' }} />
            </Avatar>
            <Label marginBottom="0px" color={colors.neutral400}>
              Selecione
            </Label>
          </>
        )}
      </Div>
      {!disabled && user && (
        <Div width={spaces.space3} height={spaces.space3} alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={faCircleXmark} onClick={handleRemove} className="remove-icon" />
        </Div>
      )}
    </SelectContainer>
  );
};

AvatarSelect.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    avatarFullpath: PropTypes.string
  }),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  cleanValue: PropTypes.string,
  open: PropTypes.bool
};

export default AvatarSelect;
