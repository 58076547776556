import styled, { css } from 'styled-components';
import { Radio, Space, Typography } from 'antd';
import { borderSolid, breakpoints, colors, fonts, radius, spaces } from '../../../styles/style';

export const Container = styled.div`
  display: grid;
  gap: ${spaces.space1};
  ${props =>
    !props.$flexColumn &&
    css`
      grid-template-columns: ${props.$isSchema ? 'repeat(2, 1fr)' : '1fr'};
    `}
`;

export const DescriptionContainer = styled.div`
  ${props =>
    props.$isSchema &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: ${spaces.space1};
    `}
`;

export const TextSchema = styled(Typography.Text)`
  color: ${colors.neutral500};
  margin-bottom: ${spaces.space0};
  font-weight: ${fonts.weight400};
  font-size: ${fonts.sizeSm};
`;

export const ContentBox = styled(Space)`
  span {
    line-height: 32px;
  }
  ${props =>
    props.$isSchema
      ? css`
          display: grid;
          @media (max-width: ${breakpoints.tablet}) {
            width: auto;
            grid-template-columns: 1fr;
          }
          width: ${props.width ? props.width : '992px'};
          grid-template-columns: ${props.grid ? props.grid : 'repeat(4, 1fr)'};
          padding-bottom: ${spaces.space2};
          padding-top: ${spaces.space2};
        `
      : css`
          size: 12px;
          flex-direction: column;
          align-items: flex-start;
        `}
`;

const Content = styled.div`
  overflow: auto;
  height: 100%;
  margin-bottom: -${spaces.space1};
  padding-top: ${props => props.$paddingTop};

  h1,
  h4,
  h5 {
    font-weight: 400;
    padding-bottom: ${spaces.space2};
  }

  @media (max-width: ${breakpoints.tablet}) {
    h1 {
      font-size: ${fonts.sizeH6};
    }
    padding: ${spaces.space7} ${spaces.space3} ${spaces.space3} ${spaces.space3};
  }

  ${props =>
    props.$center &&
    css`
      text-align: center;

      button {
        margin: auto;
      }
    `}
`;

const StepBox = styled.div`
  height: auto;
  overflow-y: auto;
  width: 100%;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .stepColumn {
    width: 60%;
    padding: ${spaces.space5} 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    .stepsSubtitle {
      margin: ${spaces.space1} 0 ${spaces.space2};
    }
  }

  .secondColumn {
    width: 40%;
    background: ${colors.neutral75};
    z-index: 2;

    img {
      margin: ${props => (props.$imageMargin ? props.$imageMargin : '15% 0 0 0')};
      width: 700px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    .stepColumn {
      padding: 0;
      width: 100%;
    }

    .secondColumn {
      display: none;
    }
  }
`;

const StepsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 520px;
  margin: 0 17%;
  padding: ${spaces.space1} 0 ${spaces.space2};

  gap: ${spaces.space2};

  @media (max-width: ${breakpoints.tablet}) {
    margin: 0;
    padding: ${spaces.space6} ${spaces.space2};
  }
`;

const Columns = styled.div`
  display: flex;
  height: 100%;

  span {
    line-height: ${fonts.sizeLg};
  }
`;

const FinishSteps = styled.div`
  display: flex;
  width: 840px;
  margin: ${spaces.space5} auto;
  border: ${borderSolid};
  border-radius: ${radius.radius1};

  .largeText {
    font-size: ${fonts.sizeLg};
    font-weight: 400;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-flow: column nowrap;
    width: 100%;
  }
`;

const AboutContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spaces.space6} ${spaces.space2};
  gap: ${spaces.space2};

  @media (min-width: ${breakpoints.mobile}) {
    width: 80%;
    padding: ${spaces.space6} 0;
  }
`;

const TextSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${spaces.space1} 0 ${spaces.space2};
  gap: ${spaces.space1};

  .ant-select-selector {
    width: 600px !important;

    @media (max-width: ${breakpoints.mobile}) {
      width: 320px !important;
    }
  }
`;

const AboutYouCardGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: ${spaces.space1};
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.mobile}) {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${spaces.space2};
  }
`;

const Cell = styled.div`
  border-right: ${borderSolid};

  &:last-of-type {
    border: none;
  }

  .cell-content {
    padding: ${spaces.space3} ${spaces.space3} 0 ${spaces.space3};
    display: flex;
    flex-flow: column nowrap;
    width: 280px;
    height: 100%;

    img {
      height: 120px;
      margin-bottom: ${spaces.space2};
    }

    .grid-footer {
      display: flex;
      align-content: center;
      justify-content: space-around;
      height: ${spaces.space6};
      border-top: ${borderSolid};
      margin-top: auto;

      img {
        height: ${spaces.space4};
        margin-bottom: 0;
      }

      a {
        align-self: center;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    border-right: none;
    border-bottom: ${borderSolid};
    .cell-content {
      width: 100%;

      .grid-footer {
        border: none;
      }
    }
  }
`;

const Card = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 ${spaces.space2};
  gap: ${spaces.space1};
  border-radius: ${radius.radius1};
  background-color: ${colors.primary50};
  cursor: pointer;
  font-weight: ${fonts.weight600};

  ${props =>
    !props.$isSelected &&
    css`
      &:hover {
        border: 1px solid ${colors.primary200};
      }

      .ant-typography {
        color: ${colors.primary400};
      }
    `};

  ${props =>
    props.$isSelected &&
    css`
      border: 1px solid ${colors.primary200};
      background-color: ${colors.primary100};

      .ant-typography {
        color: ${colors.primary700};
      }
    `};

  @media (min-width: ${breakpoints.mobile}) {
    width: 240px;
    height: 144px;
    align-items: center;
  }
`;

const IconCard = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${spaces.space2};
  padding: ${spaces.space2};
  border-radius: ${radius.radius1};
  cursor: pointer;

  ${props =>
    !props.$isSelected &&
    css`
      background-color: ${colors.primary50};

      &:hover {
        border: 1px solid ${colors.primary200};
      }

      .ant-typography {
        color: ${colors.primary400};
      }

      svg {
        color: ${colors.primary400};
      }
    `};

  ${props =>
    props.$isSelected &&
    css`
      border: 1px solid ${colors.primary200};
      background-color: ${colors.primary100};

      .ant-typography {
        color: ${colors.primary700};
      }

      svg {
        color: ${colors.primary700};
      }
    `};

  @media (min-width: ${breakpoints.mobile}) {
    width: 224px;
    height: 136px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ant-typography {
      height: ${spaces.space4};
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  padding: 0 0 ${spaces.space1};
  gap: ${spaces.space2};

  @media (min-width: ${breakpoints.mobile}) {
    padding: ${spaces.space2} 0;
  }
`;

const StyledRadio = styled(Radio)`
  width: 100%;
  padding: ${spaces.space2};
  border-radius: ${radius.radius1};
  background-color: ${colors.primary50};
  color: ${colors.primary500};
  font-weight: ${fonts.weight600};
  min-height: 80px;

  ${props =>
    props.$selected &&
    `
    background-color: ${colors.primary100};
    border: 1px solid ${colors.primary200};
    color: ${colors.primary700};

  .ant-typography {
    color: ${colors.primary600};
  }
  `}

  span {
    display: flex;
    flex-direction: column;
    gap: ${spaces.space1};
  }

  .ant-typography {
    color: ${colors.primary500};
    font-size: ${fonts.sizeSm};
    font-weight: ${fonts.weight500};
    text-align: justify;
  }
`;

export {
  Content,
  StepBox,
  StepsContainer,
  StepsContent,
  Columns,
  FinishSteps,
  Cell,
  AboutContent,
  TextSelectContainer,
  AboutYouCardGroup,
  Card,
  IconCard,
  StyledRadioGroup,
  StyledRadio
};
