import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useCRUD from './useCRUD';
import { getBrazilianDate } from '../lib/helpers/helper';
import {
  handleDateRelatedFieldChange,
  handleLinkAdd,
  handleLinkDelete,
  handleLinkUpdate
} from '../lib/helpers/ganttHelperFunctions';

const useRefurbishPlanningDrawer = ({
  data,
  setIsSubmitting,
  setNewFormValues,
  newFormValues,
  setHasChanges,
  setSelectedItems,
  afterSubmit,
  ganttInstance
}) => {
  const [ganttChanges, setGanttChanges] = useState({
    updatedLinks: {},
    updatedTasks: {},
    deletedLinks: {},
    createdLinks: {}
  });

  const { handleUpdate } = useCRUD({
    model: 'refurbishItems',
    pathOptions: `/bulkUpdateWithPlanningLinks`,
    immediatelyLoadData: false
  });

  const onChangeDateRelatedField = field => val => {
    handleDateRelatedFieldChange(ganttInstance, data, newFormValues, setNewFormValues, field, val, {
      startDateField: 'plStartDate',
      endDateField: 'plEndDate'
    });
  };

  useEffect(() => {
    if (!ganttInstance || !data) return;

    const linkUpdateHandler = handleLinkUpdate(ganttInstance, data, setGanttChanges, setSelectedItems, setHasChanges);
    const linkDeleteHandler = handleLinkDelete(ganttInstance, data, setGanttChanges, setHasChanges);
    const linkAddHandler = handleLinkAdd(ganttInstance, data, setGanttChanges, setHasChanges);

    const handleTaskUpdate = (taskId, task) => {
      setGanttChanges(prev => ({
        ...prev,
        updatedTasks: { ...prev.updatedTasks, [taskId]: task }
      }));

      if (taskId === data?.id) {
        const startDate = getBrazilianDate(task?.start_date).format('YYYY-MM-DD');
        const endDate = getBrazilianDate(task?.end_date).format('YYYY-MM-DD');

        setNewFormValues(prev => ({
          ...(prev || {}),
          plStartDate: startDate,
          plEndDate: endDate,
          duration: task?.duration
        }));
      }
      setHasChanges(true);
    };

    ganttInstance.attachEvent('onAfterLinkUpdate', linkUpdateHandler);
    ganttInstance.attachEvent('onAfterLinkDelete', linkDeleteHandler);
    ganttInstance.attachEvent('onAfterLinkAdd', linkAddHandler);
    ganttInstance.attachEvent('onAfterTaskUpdate', handleTaskUpdate);

    // eslint-disable-next-line consistent-return
    return () => {
      ganttInstance.detachEvent('onAfterLinkUpdate');
      ganttInstance.detachEvent('onAfterLinkDelete');
      ganttInstance.detachEvent('onAfterLinkAdd');
      ganttInstance.detachEvent('onAfterTaskUpdate');
    };
  }, [ganttInstance, data]);

  const prepareData = parsedData => {
    const { responsible, supplier, quantity, code, parent, type: _type, user, ...rest } = parsedData;
    return { ...rest, plIdResponsible: responsible?.id || null };
  };
  const handleSubmit = (submitData, files) => {
    setIsSubmitting(true);
    const preparedData = prepareData({ ...submitData, isCurrentTask: true });

    const updatedTasksWithoutPlDates = {};
    Object.entries(ganttChanges.updatedTasks).forEach(([taskId, taskData]) => {
      const { plStartDate, plEndDate, ...taskDataWithoutPlDates } = taskData;
      updatedTasksWithoutPlDates[taskId] = taskDataWithoutPlDates;
    });

    const payload = {
      ...ganttChanges,
      updatedTasks: {
        ...updatedTasksWithoutPlDates,
        [data?.id]: preparedData
      },
      files
    };

    handleUpdate({
      values: { ...payload, idRefurbish: data?.idRefurbish, from: 'Drawer' },
      refresh: false
    })
      .then(resp => {
        afterSubmit(resp);
        return resp;
      })
      .catch(error => {
        setIsSubmitting(false);
        setHasChanges(false);
        throw error;
      });
  };

  const updateLink = (link, updates) => {
    setSelectedItems(prev =>
      prev?.map(item => {
        if (item?.id === link?.id) {
          const updatedItem = { ...item, ...updates };

          if (ganttInstance && updatedItem.id) {
            const ganttLink = ganttInstance.getLink(updatedItem.id);
            if (ganttLink) {
              ganttLink.source = updatedItem.source;
              ganttLink.type = updatedItem.type;
              ganttLink.target = data.id;
              ganttLink.lag = updatedItem.lag;
              ganttInstance.updateLink(updatedItem.id);
            }
          }

          return updatedItem;
        }
        return item;
      })
    );
  };

  const removeLink = link => {
    if (ganttInstance && link?.id) {
      ganttInstance.deleteLink(link?.id);
    }
    setSelectedItems(prev => prev.filter(item => item?.id !== link?.id));
  };

  const createLink = selectedItemId => {
    const newLink = {
      source: Number(selectedItemId),
      target: Number(data.id),
      type: ganttInstance.config.links.finish_to_start,
      lag: 0
    };

    if (!ganttInstance.isLinkAllowed(newLink)) {
      toast.error(`O link não pôde ser criado pois resultaria em uma dependência circular.`);
      return;
    }
    const linkId = ganttInstance.addLink(newLink);

    const newItem = {
      source: Number(selectedItemId),
      target: Number(data.id),
      lag: 0,
      type: ganttInstance.config.links.finish_to_start,
      id: linkId
    };
    setSelectedItems(prev => [...prev, newItem]);
  };

  return {
    createLink,
    removeLink,
    updateLink,
    handleSubmit,
    onChangeDateRelatedField,
    ganttInstance
  };
};

export default useRefurbishPlanningDrawer;
