import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { TimePicker, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCalendar, faClock, faPlus } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Div, breakpoints, colors, fonts, spaces } from '../../styles/style';
import AvatarSelect from '../Select/AvatarSelect';
import DatePicker from '../Datepicker/Datepicker';
import Input from '../Input/Input';
import Label from '../Label/Label';
import formatCurrency from '../../lib/helpers/formatCurrency';
import { getInitials, replaceDecimalNumber, toHHMM } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import { useContextHook } from '../../contexts/GeneralContext';
import useOutsideClick from '../../_Hooks/useOutsideClick';
import { Paragraph } from '../Text/Text';
import Avatar from '../AvatarEditor/Avatar';
import Select from '../Select/Select';
import ColumnWrapper from '../Table/ColumnWrapper';
import StatusSelector from '../StatusSelector/StatusSelector';

const FormInputLabel = styled(Label)`
  color: ${colors.neutral600};
  font-weight: ${fonts.weight500};
  margin-bottom: 0;
  width: ${spaces.space16};
  flex-shrink: 0;

  ${props =>
    props.$isMobile &&
    css`
      font-size: ${fonts.sizeSm};
      font-weight: ${fonts.weight500};
    `}
`;

const FormInputDiv = styled(Div)`
  cursor: ${props => (props.readOnly ? 'default' : 'pointer')};
  font-size: ${fonts.sizeSm};
  color: ${colors.neutral600};
  height: ${spaces.space4};
  border: 1px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
  padding: 0 ${spaces.space1};
  border-radius: ${spaces.space0};
  width: ${props => (props.doubleInput ? '100%' : `calc(100% - ${spaces.space16})`)};
  display: flex;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 0;
  }

  .remove-icon {
    cursor: pointer;
    font-size: ${fonts.sizeMd};
    color: transparent;
    transition: color 0.3s ease;
  }

  ${props =>
    !props.readOnly &&
    css`
      &:hover {
        background-color: ${colors.neutral75};
        border-color: ${colors.neutral75};

        .remove-icon {
          color: ${colors.neutral600};

          &:hover {
            color: ${colors.neutral700};
          }
        }

        @media (max-width: ${breakpoints.tablet}) {
          background-color: transparent;
          border-color: transparent;
        }
      }
    `}
`;

const StyledFormItemDiv = styled(Div)`
  ${props =>
    props.$isMobile
      ? css`
          flex-direction: column;
          gap: 2px;
          align-items: flex-start;
          padding: ${spaces.space2} 0 11px 0;
          border-bottom: 1px solid ${colors.neutral100};
        `
      : css`
          width: 100%;
        `}
  width: ${props => props.width || '100%'};
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  height: ${spaces.space4};

  .ant-picker-input {
    flex-direction: row-reverse;
    gap: ${spaces.space0};

    input {
      font-size: ${fonts.sizeSm};
      color: ${colors.neutral600};
    }
  }

  .ant-picker-suffix {
    margin-left: 0;
    color: ${colors.neutral400};
  }

  .ant-picker-clear {
    color: ${colors.neutral600};
  }

  &.ant-picker-focused {
    border-color: ${colors.neutral100};
    box-shadow: none;
  }

  @media (max-width: ${breakpoints.tablet}) {
    .ant-picker-input {
      input {
        font-size: ${fonts.sizeMd};
      }
    }

    border: none;
  }
`;

const getElement = ({
  _type,
  currentProps = {},
  _property,
  currentValue,
  handleOpen,
  setField,
  handleChange,
  handleBlur,
  handleChangeSelect,
  isOpen,
  setIsInputFocused,
  handleInputBlur,
  allValues,
  refetchOptions
}) => {
  const {
    type,
    value,
    onChange,
    tooltip,
    placeholder,
    readOnly,
    icon,
    iconColor,
    handleFunctions,
    property,
    unit,
    decimalCount,
    clearable,
    externalOnChange = f => f,
    minDate,
    maxDate,
    model,
    modelOptions,
    dataType,
    aliasOptions,
    Component,
    formatter = f => f,
    formId,
    splitCellWith,
    valueField,
    displayField,
    dateFormat = 'D MMM YYYY',
    CustomLabel,
    changeOptions,
    extraPropsOnOptions,
    ...props
  } = currentProps;

  let element;
  let _value;
  let _iconColor;

  const handleKey = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  switch (_type) {
    case 'timeHours':
      element = (
        <StyledTimePicker
          id={_property}
          disabled={readOnly}
          defaultOpenValue={moment(formatter(currentValue) || '00:00', 'HH:mm')}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          suffixIcon={<FontAwesomeIcon icon={faClock} size="sm" />}
          onChange={hour => {
            setField(_property)(hour?.format('HH:mm'));
            handleOpen(false, _property);
          }}
          onSelect={hour => {
            setField(_property)(hour?.format('HH:mm'));
            handleOpen(false, _property);
          }}
          format="HH:mm"
          value={currentValue ? moment(currentValue, 'HH:mm') : null}
          placeholder="Selecionar hora"
          autoFocus
          open
        />
      );

      if (typeof currentValue === 'number') {
        _value = toHHMM(currentValue);
      } else {
        _value = readOnly ? '-' : placeholder || '';
        currentValue && (_value = currentValue);
      }
      return { element, value: _value };

    case 'statusDropdown':
      // eslint-disable-next-line no-case-declarations
      const { [dataType]: systemData = [] } = useSelector(state => state.setup.systemData);

      // eslint-disable-next-line no-case-declarations
      const currentValueObject =
        typeof currentValue === 'object' ? currentValue : systemData.find(item => item.value === currentValue);

      element = (
        <ColumnWrapper
          loadList={systemData}
          value={currentValueObject}
          onSelect={(_, __, status) => {
            handleChangeSelect(_property, status);
            handleOpen(false, _property);
          }}
          IconSelector={({ item }) => <StatusSelector icon={icon} status={item} />}
          cleanValue={null}
          canCreate={false}
          alwaysOpen
          className="form-component"
        >
          {currentValue ? (
            <StatusSelector text={currentValueObject?.label} icon={icon} status={currentValueObject} />
          ) : (
            '-'
          )}
        </ColumnWrapper>
      );

      _value = currentValueObject?.label || placeholder || '-';
      if ((_value !== '-' || placeholder) && CustomLabel) _value = CustomLabel(currentValueObject);

      return { element, value: _value, iconColor: colors[currentValueObject?.style] };

    case 'select':
      element = (
        <Select
          name={_property}
          autoFocus
          open
          id={_property}
          model={model}
          sendFullObject
          modelOptions={modelOptions}
          extraPropsOnOptions={extraPropsOnOptions}
          placeholder={placeholder || 'Selecione...'}
          value={valueField ? currentValue?.[valueField] || '' : currentValue || ''}
          onChange={(newValue, fullObject) => {
            handleChangeSelect(_property, fullObject);
            if (changeOptions) {
              changeOptions.forEach(option => {
                setField(option)(null);
              });
            }
            handleOpen(false, _property);
          }}
          onBlur={_ => {
            handleBlur(_property)(_);
          }}
          className="form-component"
          allowClear={clearable}
          dataType={dataType}
          icon={icon ? <FontAwesomeIcon icon={icon} color={valueField ? currentValue?.color : iconColor} /> : null}
          aliasOptions={aliasOptions}
          refetchOptions={refetchOptions}
        />
      );
      _value = valueField ? currentValue?.[displayField] || placeholder || '' : currentValue || placeholder || '';
      _iconColor = valueField ? currentValue?.color : iconColor;
      return { element, value: _value, iconColor: _iconColor };

    case 'date':
      element = (
        <DatePicker
          suffixIcon={<FontAwesomeIcon icon={faCalendar} size="sm" />}
          className="form-component"
          format={dateFormat}
          open
          autoFocus
          style={{ flexGrow: 1, width: '100%' }}
          clearIcon={<FontAwesomeIcon icon={faCircleXmark} />}
          value={currentValue ? dayjs(currentValue) : null}
          onChange={date => {
            setField(_property)(date);
            handleOpen(false, _property);
            externalOnChange(date);
          }}
          disabled={readOnly}
          minDate={minDate}
          maxDate={maxDate}
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      if (allValues?.[_property] || currentValue) _value = dayjs(currentValue).format(dateFormat);
      return { element, value: _value };

    case 'avatar':
      element = (
        <AvatarSelect
          style={{ flexGrow: 1 }}
          type="text"
          user={currentValue}
          onChange={selectedId => {
            handleChangeSelect(_property, selectedId, false, false);
            handleOpen(false, _property);
          }}
          className="form-component"
          cleanValue={null}
          open
          disabled={readOnly}
          {...props}
        />
      );
      _value = currentValue?.id ? (
        <Avatar id={`${currentValue?.id}-${currentValue?.name}`} size="small" src={currentValue?.avatarFullpath}>
          <Label margin={spaces.space0} color={colors.white} fontWeight={fonts.weight500}>
            {getInitials(currentValue?.name)}
          </Label>
        </Avatar>
      ) : (
        <>
          {readOnly ? (
            '-'
          ) : (
            <Div gap={spaces.space1}>
              <Avatar
                id={`${currentValue?.id}-${currentValue?.name}`}
                size="small"
                src={currentValue?.avatarFullpath}
                dashed
              >
                <FontAwesomeIcon icon={faPlus} color={colors.neutral400} style={{ verticalAlign: '-1px' }} />
              </Avatar>
              <Label marginBottom="0px" color={colors.neutral400}>
                Selecione
              </Label>
            </Div>
          )}
        </>
      );
      return { element, value: _value };

    case 'number':
      element = (
        <Input
          value={
            currentValue
              ? formatCurrency(currentValue, {
                  decimalCount:
                    decimalCount !== undefined && decimalCount !== null
                      ? decimalCount
                      : replaceDecimalNumber(currentValue)
                })
              : null
          }
          onChange={e => {
            const newValue = e.target.value.length ? e.target.value : 0;
            setField(_property)(newValue);
          }}
          onFocus={() => setIsInputFocused(true)}
          onBlur={handleInputBlur}
          onKeyDown={e => {
            const newValue = e.target.value.length ? e.target.value : 0;
            setField(_property)(newValue);
            handleKey(e);
          }}
          className="form-component"
          placeholder={placeholder}
          readOnly={readOnly}
          unit={unit}
          autoFocus
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '-';
      if (currentValue !== null && currentValue !== undefined)
        _value = `${formatCurrency(currentValue, {
          decimalCount:
            decimalCount !== undefined && decimalCount !== null ? decimalCount : replaceDecimalNumber(currentValue)
        })} ${unit || ''}`;
      return { element, value: _value };

    case 'custom':
      element = (
        <Component
          value={currentValue}
          readOnly={readOnly}
          id={_property}
          formId={formId}
          externalSetOpen={handleOpen}
          property={_property}
          open={isOpen}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      currentValue && (_value = formatter(currentValue));
      return { element, value: _value };

    default:
      element = (
        <Input
          value={currentValue}
          onChange={handleChange(_property)}
          onBlur={handleBlur(_property)}
          className="form-component"
          placeholder={placeholder}
          readOnly={readOnly}
          prefix={
            icon ? (
              <Div width={spaces.space2} height={spaces.space2} align="center" justify="center">
                <FontAwesomeIcon icon={icon} color={iconColor} />
              </Div>
            ) : null
          }
          {...props}
        />
      );
      _value = readOnly ? '-' : placeholder || '';
      currentValue && (_value = currentValue);
      return { element, value: _value };
  }
};

const FormInputContent = ({
  currentValue,
  currentProperty,
  readOnly,
  handleOpen,
  icon,
  iconColor = colors.neutral400,
  tooltip,
  cantEditTooltip,
  isMobile,
  placeholder,
  clearable,
  handleClear,
  externalOnBlur,
  doubleInput
}) => {
  return (
    <FormInputDiv
      doubleInput={doubleInput}
      onClick={() => !readOnly && handleOpen(true, currentProperty)}
      readOnly={readOnly}
    >
      {icon && (
        <Div
          width={spaces.space2}
          height={spaces.space2}
          align="center"
          justify="center"
          margin={`0 ${spaces.space0} 0 0`}
          style={{ flexShrink: 0, fontSize: fonts.sizeMd }}
        >
          <FontAwesomeIcon icon={icon} color={iconColor} size="sm" />
        </Div>
      )}
      {tooltip || cantEditTooltip ? (
        <Tooltip placement="bottomRight" title={cantEditTooltip || currentValue}>
          <Paragraph
            type={isMobile ? 'medium' : 'small'}
            ellipsis
            style={{ width: '100%' }}
            weight={readOnly ? fonts.weight600 : 'auto'}
          >
            {currentValue}
          </Paragraph>
        </Tooltip>
      ) : (
        <Paragraph
          type={isMobile ? 'medium' : 'small'}
          ellipsis
          style={{ width: '100%' }}
          color={(() => {
            if (readOnly) {
              return colors.neutral600;
            }
            if (currentValue === null || currentValue === undefined || currentValue === placeholder) {
              return colors.neutral400;
            }
            return colors.neutral600;
          })()}
          weight={readOnly ? fonts.weight600 : 'auto'}
        >
          {currentValue}
        </Paragraph>
      )}
      {!readOnly && clearable && currentValue !== '' && currentValue !== null && currentValue !== undefined && (
        <Div width={spaces.space3} height={spaces.space3} align="center" justify="center" style={{ flexShrink: 0 }}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            onClick={e => {
              e.stopPropagation();
              handleClear(currentProperty);
              externalOnBlur && externalOnBlur(null);
            }}
            className="remove-icon"
          />
        </Div>
      )}
    </FormInputDiv>
  );
};

FormInputContent.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.any]),
  currentProperty: PropTypes.string,
  readOnly: PropTypes.bool,
  handleOpen: PropTypes.func,
  icon: PropTypes.instanceOf(Object),
  iconColor: PropTypes.string,
  tooltip: PropTypes.bool,
  cantEditTooltip: PropTypes.string,
  isMobile: PropTypes.bool,
  clearable: PropTypes.bool,
  handleClear: PropTypes.func,
  externalOnBlur: PropTypes.func,
  doubleInput: PropTypes.bool,
  placeholder: PropTypes.string
};

const FormItemCreatorV2 = () => {
  const {
    type,
    label,
    value,
    tooltip,
    handleFunctions,
    property,
    clearable,
    externalOnBlur = f => f,
    allValues,
    parent,
    parentValues,
    cantEditTooltip,
    ...props
  } = useContextHook();

  const [_parent] = useState(parent);
  const [refetchOptions, setRefetchOptions] = useState();

  useEffect(() => {
    if (_parent) {
      if (parentValues[_parent?.name]) {
        if (type === 'select')
          setRefetchOptions(_parent?.makeRefetchOptions({ field: _parent?.name, value: parentValues[_parent?.name] }));
      }
    }
  }, [parentValues, _parent]);

  const { setField, handleChange, handleBlur, handleChangeSelect, handleClear } = handleFunctions;
  const [isOpen, setIsOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState({});
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();
  const handleOpen = (v, key) => {
    if (Array.isArray(type)) {
      setIsTypeOpen({ [key]: v });
    } else {
      setIsOpen(v);
    }
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
    if (externalOnBlur) {
      externalOnBlur(value || 0);
    }
  };

  useEffect(() => {
    if (!isOpen && isInputFocused) {
      handleInputBlur();
    }
  }, [isOpen, isInputFocused]);

  let element;
  let _value;
  let _property;
  let _iconColor;
  if (Array.isArray(type)) {
    const aggregatedElements = type.reduce(
      (acc, curr) => {
        const currentProperty = props?.[`${curr}Props`]?.property;

        const { element: _element, value: __value } = getElement({
          _type: curr,
          currentProps: { ...props, ...props?.[`${curr}Props`] },
          _property: currentProperty,
          currentValue: allValues?.[currentProperty],
          handleOpen,
          setField,
          handleChange,
          handleBlur,
          handleChangeSelect,
          isOpen,
          setIsInputFocused,
          handleInputBlur,
          allValues
        });
        acc.element.push(_element);
        acc.value.push(__value);
        acc.property.push(currentProperty);
        return acc;
      },
      { element: [], value: [], property: [] }
    );
    element = aggregatedElements.element;
    _value = aggregatedElements.value;
    _property = aggregatedElements.property;
  } else {
    const { element: _element, value: __value, iconColor: __iconColor } = getElement({
      _type: type,
      currentProps: props,
      _property: property,
      currentValue: value,
      handleOpen,
      setField,
      handleChange,
      handleBlur,
      handleChangeSelect,
      isOpen,
      setIsInputFocused,
      handleInputBlur,
      refetchOptions,
      isMobile
    });
    element = _element;
    _value = __value;
    _property = property;
    _iconColor = __iconColor;
  }

  const ref = useRef(null);
  useOutsideClick(
    ref,
    () => {
      setIsOpen(false);
      setIsTypeOpen({});
    },
    null,
    { classes: ['ant-picker-dropdown', 'ant-select-dropdown', 'ant-dropdown', 'ant-modal-content'], depth: 20 }
  );

  return (
    <StyledFormItemDiv $isMobile={isMobile} width={props.width} ref={ref}>
      <FormInputLabel $isMobile={isMobile}>{label}</FormInputLabel>
      {Array.isArray(element) ? (
        <Div width="100%" gap={spaces.space0}>
          {_property.map((e, index) =>
            isTypeOpen?.[e] ? (
              <>{element[index]}</>
            ) : (
              <FormInputContent
                currentValue={_value[index]}
                currentProperty={_property[index]}
                handleOpen={handleOpen}
                tooltip={tooltip}
                isMobile={isMobile}
                clearable={clearable}
                handleClear={handleClear}
                externalOnBlur={externalOnBlur}
                doubleInput
                {...props}
                {...props?.[`${type[index]}Props`]}
              />
            )
          )}
        </Div>
      ) : (
        <>
          {isOpen ? (
            element
          ) : (
            <FormInputContent
              currentValue={_value}
              currentProperty={property}
              handleOpen={handleOpen}
              iconColor={_iconColor}
              tooltip={tooltip}
              cantEditTooltip={cantEditTooltip}
              isMobile={isMobile}
              clearable={clearable}
              handleClear={handleClear}
              externalOnBlur={externalOnBlur}
              {...props}
            />
          )}
        </>
      )}
    </StyledFormItemDiv>
  );
};

FormItemCreatorV2.propTypes = {
  width: PropTypes.string,
  handleFunctions: PropTypes.shape({
    setField: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    handleChangeSelect: PropTypes.func.isRequired
  }).isRequired,
  property: PropTypes.string.isRequired
};

export default FormItemCreatorV2;
