import { useState, useEffect } from 'react';

const usePagination = ({ totalItems, handleLoad, listLength, filter, order, initialOffset }) => {
  const [offset, setOffset] = useState(initialOffset);
  const [_totalItems, setTotalItems] = useState(totalItems);

  const handleScroll = () => {
    setOffset(prev => prev + 1);
  };

  useEffect(() => {
    if (offset > 1 && (!_totalItems || _totalItems > listLength)) handleLoad({ delay: false, offset });
  }, [offset]);

  useEffect(() => {
    if (offset === 1) handleLoad({ delay: true, offset });
    else setOffset(1);
  }, [filter, order]);

  return {
    offset,
    setOffset,
    setTotalItems,
    handleScroll
  };
};

export default usePagination;
